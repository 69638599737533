import React from 'react';
import _ from 'lodash';
import { Button } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import { BuyerPurchaseDetailsContainer } from 'react-platform-purchase/dist/PurchaseOfBuyer/BuyerPurchaseDetails/BuyerPurchaseDetailsContainer';
import { BuyerPurchaseDetails } from 'react-platform-purchase/dist/PurchaseOfBuyer/BuyerPurchaseDetails/BuyerPurchaseDetails';
import { ReservationDetails as ReservationDetailsDefault } from 'react-platform-reservation/dist/ReservationDetailsOfPurchase/ReservationDetails';
import { PurchaseSalableItemsBySeller as DefaultPurchaseSalableItemsBySeller } from 'react-platform-purchase/dist/PurchaseOfBuyer/BuyerPurchaseDetails/components/PurchaseSalableItemsBySeller'
import { PurchaseSalableItem as PurchaseSalableItemDefault } from 'react-platform-purchase/dist/PurchaseOfBuyer/BuyerPurchaseDetails/components/PurchaseSalableItem'
import { IntlMessages } from 'react-platform-components/dist/Translations';
import { ConfirmationDialog } from 'react-platform-components/dist/components/ConfrimationDialog/ConfrimationDialog';
import { MultiFileDownloader } from 'react-platform-components/dist/uploader/MultiFileUploaderAdvanced';

export const PurchaseOfBuyerDetailsContainer = () => {
    return <BuyerPurchaseDetailsContainer listComponent={PurchaseOfBuyerDetails} />;
};

const PurchaseOfBuyerDetails = () => {
    return <BuyerPurchaseDetails reservationDetails={ReservationDetails} purchaseSalableItemsBySeller={PurchaseSalableItemsBySeller} />;
}

const ReservationDetails = (props) => {
    return <ReservationDetailsDefault {...props} getBasicInfo={(basicInfo) => basicInfo.filter(info => info.id === 'startDate')} />;
};

const PurchaseSalableItemsBySeller = (props) => {
    return <DefaultPurchaseSalableItemsBySeller {...props} purchaseSalableItem={PurchaseSalableItem} />;
};

const PurchaseSalableItem = (props) => {
    return <PurchaseSalableItemDefault {...props} renderExtraButtons={DownloadFileButton} />;
}

export const DownloadFileButton = ({ purchaseItem }) => {
    if (_.isEmpty(purchaseItem.seller_extension?.files)) return null;
    return (
        <ConfirmationDialog
            title="download_files_dialog_title"
            onConfirm={(closeDialog) => closeDialog()}
            disableCloseAction
            content={
                <MultiFileDownloader files={purchaseItem.seller_extension?.files} />
            }
            confirmLabel="OK_download_files"
            component={({ onOpen }) => (
                <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    disableElevation
                    startIcon={<CloudDownloadIcon />}
                    onClick={onOpen}
                >
                    <IntlMessages id="download_files" />
                </Button>
            )}
        />
    );
}
