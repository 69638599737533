import React from 'react';
import { connect } from 'react-redux';
import {Layout} from 'react-platform-components/dist'
import { routes } from '../../init'


class App extends React.Component {

    render() {
        return <Layout routes={routes} {...this.props} />
    }
}

export default connect((state) => {
    return {
        user: state.auth.user
    }
})(App);
