import _ from 'lodash';
import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import { connect, useDispatch } from 'react-redux';
import { styles } from 'react-platform-product/dist/Product/ProductSyles';
import { injectToBaseContainer } from 'react-platform-components/dist/BaseComponents/BaseInjectors';
import { defaultConfig } from 'react-platform-productsalable/dist/ProductSalable/ProductSalableContainer';
import ProductSalableList from 'react-platform-productsalable/dist/ProductSalable/ProductSalableList/ProductSalableList';
import { Filters } from 'react-platform-productsalable/dist/ProductSalable/ProductSalableList/components/Filters';
import {
    useCategoriesDefault,
    useProductsDefault,
} from 'react-platform-productsalable/dist/ProductSalable/tableHooks';
import { IntlMessages } from 'react-platform-components/dist/Translations';
import { CATEGORIES_OF_PRODUCT_FIELDS_TO_FETCH_IN_CREATION_OF_PRODUCT } from 'react-platform-category-of-product/dist/config';
import { ImageButtons as ImageButtonsDefault } from 'react-platform-productsalable/dist/ProductSalable/ProductSalableList/components/ImageButtons';
import { ProductInfo as ProductInfoDefault } from 'react-platform-productsalable/dist/ProductSalable/ProductSalableList/components/ProductInfo';
import { ActionsButtons as ActionsButtonsDefault } from 'react-platform-productsalable/dist/ProductSalable/ProductSalableList/components/ActionsButtons';
import { MainButtons as MainButtonsDefault } from 'react-platform-productsalable/dist/ProductSalable/ProductSalableList/components/MainButtons';
import { TopButtons as TopButtonsDefault } from 'react-platform-productsalable/dist/ProductSalable/ProductSalableList/components/TopButtons';
import { TopChips as TopChipsDefault } from 'react-platform-productsalable/dist/ProductSalable/ProductSalableList/components/TopChips';
import { DemandDialog } from '../Demand/DemandDialog';
import { SearchDialog } from './components/SearchDialog';
import { DELETE_REAL_ESTATE } from './RealEstateListActions';
import { FETCH_CATEGORIES_OF_SERVICE } from '../Services/ServicesActions';

const useProducts = (params) => {
    return useProductsDefault({
        ...params,
        FETCH_ACTION: 'FETCH_REAL_ESTATES',
    });
};

const RealEstateList = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: FETCH_CATEGORIES_OF_SERVICE,
            params: {
                _fields: [
                    ...CATEGORIES_OF_PRODUCT_FIELDS_TO_FETCH_IN_CREATION_OF_PRODUCT,
                    'sub_categories',
                    'parent_category',
                ],
            },
        });
    }, []);

    return (
        <ProductSalableList
            {...props}
            useProducts={useProducts}
            extraProps={{
                ...props.extraProps,
                filtersComponent: RealEstateFilters,
            }}
        />
    );
};

const ImageButtons = (props) => {
    return (
        <ImageButtonsDefault
            {...props}
            reviewsButtonComponent={() => <></>}
            reservationButtonComponent={() => <></>}
            visualizeButtonComponent={() => <></>}
        />
    );
};

const MainButtons = (props) => {
    return (
        <MainButtonsDefault
            {...props}
            reservationButtonComponent={() => <></>}
        />
    );
};

const ActionsButtons = (props) => {
    return (
        <ActionsButtonsDefault
            {...props}
            mainButtonsComponent={MainButtons}
            reviewsButtonComponent={() => <></>}
            visualizeButtonComponent={() => <></>}
        />
    );
};

const ProductInfo = (props) => {
    return (
        <ProductInfoDefault
            {...props}
            actionsButtonsComponent={ActionsButtons}
        />
    );
};

const TopButtons = (props) => {
    return (
        <TopButtonsDefault
            {...props}
            featuredTagComponent={() => <></>}
            renderExtraButtons={(product) => (
                <>
                    <DemandDialog
                        product={'demand' in product ? product.demand : product}
                    />
                    <SearchDialog
                        product={'demand' in product ? product.demand : product}
                    />
                </>
            )}
        />
    );
};

const TopChips = (props) => {
    return <TopChipsDefault {...props} productStatusComponent={() => <></>} />;
}

export default injectToBaseContainer(
    _.flowRight(
        withStyles(styles),
        connect((state) => {
            return {
                pagination: state.productSalableReducer.pagination,
            };
        }),
    ),
    {
        ...defaultConfig,
        headerTitle: 'real estate',
        createButtonText: <IntlMessages id="create real estate" />,
        creationTypeEndpoint: 'create-real-estate',
        productCreationEndpoint: 'create-real-estate',
        DELETE_ACTION: DELETE_REAL_ESTATE,
        detailEndpoint: 'create-real-estate',
        listComponent: RealEstateList,
        extraProps: {
            ...defaultConfig.extraProps,
            topButtonsComponent: TopButtons,
            imageButtonsComponent: ImageButtons,
            productInfoComponent: ProductInfo,
            topChipsComponent: TopChips,
            getReducers: (state) => ({
                products: state.ProductReducer.products,
                pagination: state.ProductReducer.pagination,
                inProgress: state.ProductReducer.isProductsFetchingInProgress,
            }),
        },
    },
);

const useCategories = (params) => {
    return useCategoriesDefault({
        ...params,
        FETCH_ACTION: 'FETCH_CATEGORIES_OF_REAL_ESTATE',
    });
};

const RealEstateFilters = (props) => {
    return <Filters {...props} useCategories={useCategories} />;
};
