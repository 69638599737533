import { PropositionConfig as PropositionConfigDefault } from 'react-platform-proposition/dist/Proposition/PropositionConfig';
import Propositions from './Propositions';

export const PropositionConfig = {
    ...PropositionConfigDefault,
    routes: PropositionConfigDefault.routes.map((route) => ({
        ...route,
        component: Propositions,
    })),
    settings: {
        ...PropositionConfigDefault.settings,
        routeSuffix: 'propositions'
    }
};
