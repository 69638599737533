import _ from 'lodash';
import React from 'react';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';

import { styles } from 'react-platform-product/dist/Product/ProductSyles';
import { injectToBaseContainer } from 'react-platform-components/dist/BaseComponents/BaseInjectors';
import { defaultConfig } from 'react-platform-productsalable/dist/ProductSalable/ProductSalableContainer';
import ProductSalableTable, {
    TABLE_HEAD_COLUMNS,
} from 'react-platform-productsalable/dist/ProductSalable/ProductSalableTable';
import { useProductsDefault } from 'react-platform-productsalable/dist/ProductSalable/tableHooks';
import { CustomTableBody } from 'react-platform-productsalable/dist/ProductSalable/tableComponents/CustomTableBody';
import {
    RowTableMenu as RowTableMenuDefault,
    ViewMenuItemDefault,
} from 'react-platform-productsalable/dist/ProductSalable/tableComponents/bodyComponents/RowTableMenu';
import { TableToolbar as TableToolbarDefault } from 'react-platform-productsalable/dist/ProductSalable/tableComponents/TableToolbar';

import { FETCH_MATCHED_DEMANDS } from './MatchedDemandsActions';

const headColumns = TABLE_HEAD_COLUMNS.filter(
    (column) => !['price', 'quantity'].includes(column.value),
).map((column) => {
    if (column.value === 'name') {
        return {
            ...column,
            editable: false,
            isLink: true,
            reactProductDetailEndpoint: true,
        };
    }
    return column;
});

const useProducts = (params) => {
    return useProductsDefault({
        ...params,
        FETCH_ACTION: FETCH_MATCHED_DEMANDS,
        _embed: 'demand',
        _sort: '-created_at',
    });
};

const ViewMenuItem = (props) => {
    return <ViewMenuItemDefault {...props} reactProductDetailEndpoint detailEndpoint="matched-demands" />;
};

const RowTableMenu = (props) => {
    return (
        <RowTableMenuDefault
            {...props}
            viewMenuItemComponent={ViewMenuItem}
            editMenuItemComponent={() => <></>}
            reviewMenuItemComponent={() => <></>}
            deleteMenuItemComponent={() => <></>}
        />
    );
};

const TableBody = (props) => {
    return <CustomTableBody {...props} rowTableMenuComponent={RowTableMenu} />;
};

const TableToolbar = (props) => {
    return <TableToolbarDefault {...props} exportCSVcomponent={() => <></>} />;
};

export default injectToBaseContainer(
    _.flowRight(
        withStyles(styles),
        connect((state) => {
            return {
                pagination: state.productSalableReducer.pagination,
            };
        }),
    ),
    {
        ...defaultConfig,
        listComponent: (props) => (
            <ProductSalableTable
                {...props}
                getReducers={(state) => ({
                    specificities: state.specificityOfProductReducer.specificities,
                    products: state.ProductReducer.products.map((product) => {
                        return {
                            ...product.demand,
                        };
                    }),
                    inProgress: state.ProductReducer.isProductsFetchingInProgress,
                    pagination: state.ProductReducer.pagination,
                })}
                headColumns={headColumns}
                tableBodyComponent={TableBody}
                tableToolbarComponent={TableToolbar}
                useProducts={useProducts}
            />
        ),
        detailEndpoint: 'matched-demands',
        headerTitle: 'Matched Demands',
        creationButton: () => <></>,
    },
);
