import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Button, Popover } from '@material-ui/core';
import qs from 'qs';
import { IntlMessages } from 'react-platform-components/dist/Translations';
import { PRODUCTS_ENDPOINT } from 'react-platform-chat/dist/config';
import { CategoryFilterPopup } from './CategoryFilter/CategoryFilterPopup';

const RADIUS = 50;

export const SearchDialog = (props) => {
    const { product } = props;
    const { categories } = useSelector(({ servicesReducer }) => ({
        categories: servicesReducer.categories,
    }));
    const [anchor, setAnchor] = useState(null);

    const handleClick = (event) => {
        setAnchor(event.currentTarget);
    };

    const handleFilterSave = (filters) => {
        const allFilters = {
            ...filters,
        };
        if (product.address) {
            const { latitude, longitude, address_01 } = product.address;
            allFilters.inCenter =
                latitude && longitude && `${latitude},${longitude},${RADIUS}`;
            allFilters.locationTerm = address_01;
        }
        const urlParams = qs.stringify(allFilters, {
            arrayFormat: 'repeat',
        });

        window.location.href = `/${PRODUCTS_ENDPOINT}?${urlParams}`;
        setAnchor(null);
    };

    return (
        <>
            <Grid item>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClick}
                >
                    <IntlMessages id="search_service" />
                </Button>
            </Grid>
            <Popover
                open={!!anchor}
                anchorEl={anchor}
                onClose={() => setAnchor(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <CategoryFilterPopup
                    categories={categories}
                    categoriesParents={categories.filter(
                        (category) => category.parent_category_id === null,
                    )}
                    onSave={handleFilterSave}
                    onCancel={() => setAnchor(null)}
                />
            </Popover>
        </>
    );
};
