import React, { useState } from 'react';
import { Typography, Button, Paper, IconButton, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { CategoryFilter } from './CategoryFilter';
import { useAnimation, useCategoryFilter } from './hooks';
import { IntlMessages } from 'react-platform-components/dist/Translations';

const useStyles = makeStyles((theme) => ({
    backArrowIconButton: {
        '&:focus': {
            outline: 'none',
            color: 'rgba(0, 0, 0, 0.54)',
        },
        '&:hover': {
            color: 'rgba(0, 0, 0)',
        },
    },
    icon: {
        fontSize: '1.875rem',
    },
    titleContainer: {
        flexGrow: 1,
        fontSize: '1.25rem',
        padding: '12px 20px 12px 0px',
    },
    popupAnimation: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
        background: 'white',
        transition: 'transform 0.4s ease',
    },
    paper: {
        minWidth: '350px',
    },
    popupWrapper: {
        maxHeight: '600px',
        display: 'flex',
        flexDirection: 'column',
    },
    popupHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '12px 4px',
        borderStyle: 'solid !important',
        borderWidth: '0px 0px 1px 0px !important',
        borderColor: '#e1e2e1 !important',
    },
    popupContent: {
        overflow: 'auto',
    },
    popupFooter: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        margin: '1rem 1.5rem',
    },
    saveButton: {
        marginLeft: '1rem',
    },
}));

export const CATEGORY_FILTER_QUERY_KEY = 'category.sub_categories_of';

export const CategoryFilterPopup = ({
    categories,
    categoriesParents,
    onSave,
    onCancel,
}) => {
    const classes = useStyles();
    const [filters, setFilters] = useState({});
    const { animate, setAnimate } = useAnimation();
    const { selectedCategory, handleGoForward, handleGoBackward } =
        useCategoryFilter({
            categories,
            categoriesParents,
        });

    const handleCategoryClick = async (category) => {
        await handleGoForward(category);
        setAnimate('forward');
    };
    const handleGoBackwardClick = () => {
        handleGoBackward();
        setAnimate('backward');
    };

    const setFilter = (values) => {
        const newFilters = _.cloneDeep(filters);
        _.forOwn(values, (value, key) => {
            if (_.has(filters, key)) {
                if (_.isArray(value)) {
                    _.forEach(value, (v) => {
                        const filterValue = newFilters[key];
                        if (
                            _.isString(filterValue)
                                ? filterValue === v
                                : _.includes(filterValue, v)
                        ) {
                            _.remove(newFilters[key], (e) => e === v);
                        } else {
                            Array.isArray(newFilters[key])
                                ? newFilters[key].push(v)
                                : (newFilters[key] = [newFilters[key], v]);
                        }
                    });
                } else {
                    newFilters[key] = value;
                }
            } else {
                newFilters[key] = value;
            }
        });
        setFilters(newFilters);
    };

    const handleChange = (event, multi) => {
        if (multi) {
            setFilter({
                [event.currentTarget.name]: _.isArray(event.currentTarget.value)
                    ? event.currentTarget.value
                    : event.currentTarget.value.includes(',')
                    ? event.currentTarget.value.split(',')
                    : [event.currentTarget.value],
            });
        }
    };

    const handleSave = () => {
        onSave(filters);
    };

    const showClear = !_.isEmpty(filters[CATEGORY_FILTER_QUERY_KEY]);

    return (
        <Paper className={classes.paper}>
            <div
                className={classes.popupAnimation}
                style={{
                    zIndex: animate ? 1000000 : -1,
                    transform: `translateX(${
                        animate === 'forward'
                            ? 100
                            : animate === 'backward'
                            ? -100
                            : 0
                    }%)`,
                }}
            ></div>
            <div className={classes.popupWrapper}>
                <div className={classes.popupHeader}>
                    {selectedCategory && (
                        <IconButton
                            className={classes.backArrowIconButton}
                            onClick={handleGoBackwardClick}
                        >
                            <Icon className={classes.icon}>arrow_back</Icon>
                        </IconButton>
                    )}
                    <div className={classes.titleContainer}>
                        <Typography
                            variant="h5"
                            align="center"
                            color="textPrimary"
                        >
                            {selectedCategory?.name || (
                                <IntlMessages id="Categories" />
                            )}
                        </Typography>
                    </div>
                </div>

                <div className={classes.popupContent}>
                    <CategoryFilter
                        categories={categoriesParents}
                        filters={filters}
                        handleCategoryClick={handleCategoryClick}
                        selectedCategory={selectedCategory}
                        handleChange={handleChange}
                    />
                </div>
                <div className={classes.popupFooter}>
                    <Button
                        onClick={onCancel}
                        variant="text"
                        className="text-gray-600"
                    >
                        <Typography>
                            <IntlMessages
                                id="cancel_filter"
                                defaultMessage="cancel_filter"
                            />
                        </Typography>
                    </Button>

                    <Button
                        onClick={handleSave}
                        variant="outlined"
                        className={classes.saveButton}
                        style={{ color: '#2B66A6' }}
                    >
                        <Typography>
                            <IntlMessages
                                id="apply_filter"
                                defaultMessage="apply_filter"
                            />
                        </Typography>
                    </Button>
                </div>
            </div>
        </Paper>
    );
};
