import { createGenerateClassName, jssPreset, StylesProvider } from '@material-ui/styles';
import { MuiThemeProvider, createMuiTheme, darken, lighten } from "@material-ui/core"
import CssBaseline from "@material-ui/core/CssBaseline";
import { create } from 'jss';
import jssExtend from 'jss-extend';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Router } from 'react-router-dom';
import Authorization from 'react-platform-user/dist/Authorization/Authorization';
import { TranslationProvider } from "react-platform-components/dist/Translations/hoc";
import { PreloadApplication } from "react-platform-components/dist/PreloadApplication/PreloadApplication"
import { BASE_APP_URL, ENV, SENTRY_DSN } from './configs';
import { App } from './containers';
import history from './history';
import { runSaga, store } from './redux';
import { routes } from './init';
import * as serviceWorker from './serviceWorker';
import './styles/helpers.css';
import './styles/styles.css';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";



if (ENV != "dev" && SENTRY_DSN) {
    Sentry.init({
        dsn: SENTRY_DSN,
        environment: ENV,
        autoSessionTracking: true,
        integrations: [new Integrations.BrowserTracing()],
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    });
}


const PrimaryColor = '#374a61'
const SecondaryColor = typeof window.MedialemGlboalSettings === 'object'
    ? window.MedialemGlboalSettings.colors.secondary
    : window.nextPlatformMapMainSecondaryColor || "#e91e63";
const PrimaryFont = {
    fontFamily: typeof window.MedialemGlboalSettings === 'object'
        ? window.MedialemGlboalSettings.fonts.primary.typography_font_family
        : window.razzleFontFamily,
    fontWeight: typeof window.MedialemGlboalSettings === 'object'
        ? window.MedialemGlboalSettings.fonts.primary.typography_font_weight
        : '600',
};
const SecondaryFont = {
    fontFamily: typeof window.MedialemGlboalSettings === 'object'
        ? window.MedialemGlboalSettings.fonts.secondary.typography_font_family
        : window.razzleFontFamily,
    fontWeight: typeof window.MedialemGlboalSettings === 'object'
        ? window.MedialemGlboalSettings.fonts.secondary.typography_font_weight
        : '400',
};

const theme = createMuiTheme({
    typography: {
        fontFamily: SecondaryFont,
        h1: PrimaryFont,
        h2: PrimaryFont,
        button: {...SecondaryFont, textTransform: 'none'},
        body1: SecondaryFont,
        body2: SecondaryFont,
        subtitle1: PrimaryFont,
        subtitle2: PrimaryFont,
    },
    zIndex: {
        mobileStepper: 10000000000000,
        speedDial: 10500000000000,
        appBar: 11000000000000,
        drawer: 12000000000000,
        modal: 13000000000000,
        snackbar: 14000000000000,
        tooltip: 15000000000000,
    },
    spacing: 10,
    palette: {
        primary: {
            main: PrimaryColor,
            contrastText: window.nextPlatformMapContrastTextForPrimaryColor || "#ffff"
        },
        secondary: {
            main: SecondaryColor,
            contrastText: window.nextPlatformMapContrastTextForSecondaryColor || "#ffff"
        },
        third: {
            main: window.nextPlatformMapMainPrimaryColor ? lighten(window.nextPlatformMapMainPrimaryColor, 0.2) : "#FAE8E7",
            secondary: window.nextPlatformMapMainSecondaryColor ? darken(window.nextPlatformMapMainSecondaryColor, 0.2) : "#FFA4A2",
            dark: window.nextPlatformMapMainSecondaryColor ? darken(window.nextPlatformMapMainSecondaryColor, 0.7) : "#E57373",
            contrastText: "#000000",
            noContrastText: "#FFFFFF",
        },
        custom: {},
    },
    overrides: {
        MuiIconButton: {
            root: {
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
                },
            }
        },
        MuiButtonBase: {
            root: {
                '&:focus': {
                    outline: 'none !important'
                },
                '&:disabled': {
                    background: 'rgba(0, 0, 0, 0.12) !important',
                    color: 'rgba(0, 0, 0, 0.26) !important',
                    cursor: 'not-allowed',
                }
            },
        },
        MuiButton: {
            root: {
                border: 0,
                borderRadius: 100,
                height: 30,
                textTrannsform: 'none',
            },
            text: { color: '#000000' },
            containedSecondary: {
                color: '#000000 !important',
                backgroundColor: '#c6f08a !important',
                '&:hover': {
                    color : '#174913 !important',
                    backgroundColor: '#c6f08a !important',
                }
            },
            containedPrimary: {
                color: '#000000 !important',
                backgroundColor: '#c6f08a !important',
                '&:hover': {
                    color : '#174913 !important',
                    backgroundColor: '#c6f08a !important',
                }
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.12) !important'
            }
        },
        MuiInputBase: {
            input: {
                border: '0 !important',
                borderRadius: '0'
            }
        },
        MuiOutlinedInput: {
            input: {
                padding: '18.5px 14px !important'
            },
            inputAdornedEnd: {
                paddingRight: '0 !important',
            }
        },
        MuiPaper: {
            root: {
                '& img': {
                    height: '100%'
                },
            }
        },
    },
    shape: {
        borderRadius: 8,
    },
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
        MuiButton: {
            disableElevation: true,
        },
    },
})

const jss = create({
    ...jssPreset(),
    insertionPoint: 'jss-insertion-point',
    plugins: [...jssPreset().plugins, jssExtend()]
});

jss.options.createGenerateClassName = createGenerateClassName;

const generateClassName = createGenerateClassName();

runSaga();


const RootComponent = () => {
    // we use React router here becuse we need it in the root component
    return (
        <TranslationProvider>
            <Router history={history}>
                <BrowserRouter basename={BASE_APP_URL}>
                    <PreloadApplication>
                        <Authorization routes={routes}>
                            <App />
                        </Authorization>
                    </PreloadApplication>
                </BrowserRouter>
            </Router>
        </TranslationProvider>
    );
};


ReactDOM.render(
    <StylesProvider injectFirst jss={jss} generateClassName={generateClassName}>

            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <Provider store={store}>
                    <RootComponent />
                </Provider>
            </MuiThemeProvider>

    </StylesProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
