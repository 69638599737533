import { loadingBarMiddleware, loadingBarReducer } from 'react-redux-loading-bar';
import { applyMiddleware, combineReducers, createStore as _createStore, compose } from 'redux';
import createSagaMidlleware from 'redux-saga';
import { rootSaga, reducers } from '../init';
import thunk from 'redux-thunk';

const sagaMidlleware = createSagaMidlleware();

export function runSaga() {
    sagaMidlleware.run(rootSaga)
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
function createStore() {
    const middleware = composeEnhancers(applyMiddleware(...[sagaMidlleware, thunk, loadingBarMiddleware({
        promiseTypeSuffixes: ['START', 'SUCCESS', 'ERROR'],
    })]));
    const reducer = combineReducers({ ...reducers, loadingBar: loadingBarReducer });
    return _createStore(reducer, middleware);
}

export const store = createStore()

store.asyncReducers = {};

export const injectReducer = (key, reducer) => {
    if ( store.asyncReducers[key] )
    {
        return;
    }
    store.asyncReducers[key] = reducer;
    store.replaceReducer(combineReducers({...store.asyncReducers, ...reducers, loadingBar: loadingBarReducer }));
    return store;
};