import React from 'react';
import { Icon, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    buttonWrapper: {
        display: 'flex',
        borderStyle: 'solid !important',
        borderWidth: '0px 0px 1px 0px !important',
        borderColor: '#e1e2e1 !important',
        cursor: 'pointer',
        // @ts-ignore
        background: ({ color }) =>
            `${color === 'primary' ? '#fff' : '#fafafa'} !important`,
        // @ts-ignore
        animationName: ({ color }) =>
            `${color === 'primary' ? 'none' : 'fadeIn'}`,
        animationDuration: '3s',
        animationIterationCount: 2,
    },
    textAndIconWrapper: {
        padding: '21px 21px 21px 0px',
        color: '#000000 !important',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        '&:hover': {
            background: '#fafafa !important',
        },
        '& .second-text': {
            color: '#999',
            marginLeft: '0.5rem',
        },
    },
    iconButtonWrapper: {
        marginLeft: '21px',
        flexGrow: 1,
    },
    iconButtonWrapperOnFilter: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    arrowForwardButtonIcon: {
        padding: '0px !important',
        marginLeft: '0.5rem',
        '&:focus': {
            outline: 'none',
            color: 'rgba(0, 0, 0, 0.54)',
        },
        '&:hover': {
            color: 'rgba(0, 0, 0)',
        },
    },
    cancelButtonIcon: {
        padding: '21px 15px 21px 21px !important',
    },
}));

export const CustomButton = ({
    doesFilterExist,
    color = 'primary',
    showArrow = true,
    handleGoForward,
    handleCancel,
    children,
}) => {
    const classes = useStyles({ color });
    return (
        <div className={classes.buttonWrapper}>
            {doesFilterExist && (
                <IconButton
                    onClick={handleCancel}
                    className={classes.cancelButtonIcon}
                >
                    <Icon>cancel</Icon>
                </IconButton>
            )}
            <div
                onClick={handleGoForward}
                className={classes.textAndIconWrapper}
            >
                <div
                    className={
                        doesFilterExist
                            ? classes.iconButtonWrapperOnFilter
                            : classes.iconButtonWrapper
                    }
                >
                    {children}
                </div>
                {showArrow && (
                    <IconButton className={classes.arrowForwardButtonIcon}>
                        <Icon>arrow_forward_ios</Icon>
                    </IconButton>
                )}
            </div>
        </div>
    );
};
