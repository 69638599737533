import {
    UPLOAD_PURCHASE_FILES_START,
    UPLOAD_PURCHASE_FILES_SUCCESS,
    UPLOAD_PURCHASE_FILES_ERROR,
} from './PurchaseActions';

const defaultState = {
    isUploadingInProgress: false,
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        
        case UPLOAD_PURCHASE_FILES_START: {
            return {
                ...state,
                isUploadingInProgress: true,
            };
        }
        case UPLOAD_PURCHASE_FILES_SUCCESS:
            return {
                ...state,
                isUploadingInProgress: false,
            };
        case UPLOAD_PURCHASE_FILES_ERROR:
            return {
                ...state,
                isUploadingInProgress: false,
            };
        default:
            return { ...state };
    }
}
