import * as actions from './ServicesActions';

const defaultState = {
    categories: [],
    fetchCategoriesInProgress: false,
    fetchAllCategoriesInProgress: false,
};

export default function servicesReducer(state = defaultState, action) {
    switch (action.type) {
        case actions.FETCH_CATEGORIES_OF_SERVICE_START: {
            return {
                ...state,
                fetchCategoriesInProgress: true,
                fetchAllCategoriesInProgress: true,
            };
        }
        case actions.FETCH_CATEGORIES_OF_SERVICE_SUCCESS: {
            return {
                ...state,
                categories: action.payload,
                fetchCategoriesInProgress: false,
                fetchAllCategoriesInProgress: false,
            };
        }
        case actions.FETCH_CATEGORIES_OF_SERVICE_FAILURE: {
            return {
                ...state,
                fetchCategoriesInProgress: false,
                fetchAllCategoriesInProgress: false,
            };
        }
        default:
            return { ...state };
    }
}
