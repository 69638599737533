import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

import { injectToBaseContainer } from 'react-platform-components/dist/BaseComponents/BaseInjectors';
import { IntlMessages } from 'react-platform-components/dist/Translations';
import { styles } from 'react-platform-product/dist/Product/ProductSyles';
import { defaultConfig } from 'react-platform-productsalable/dist/ProductSalable/ProductSalableContainer';
import ProductSalableTable, {
    TABLE_HEAD_COLUMNS,
} from 'react-platform-productsalable/dist/ProductSalable/ProductSalableTable';
import { useProductsDefault } from 'react-platform-productsalable/dist/ProductSalable/tableHooks';
import { CustomTableBody } from 'react-platform-productsalable/dist/ProductSalable/tableComponents/CustomTableBody';
import {
    RowTableMenu as RowTableMenuDefault,
    ViewMenuItemDefault,
} from 'react-platform-productsalable/dist/ProductSalable/tableComponents/bodyComponents/RowTableMenu';
import { TableToolbar as TableToolbarDefault } from 'react-platform-productsalable/dist/ProductSalable/tableComponents/TableToolbar';

import { DemandDialog } from './DemandDialog';
import { FETCH_DEMANDS, DELETE_DEMAND } from './DemandActions';

const headColumns = TABLE_HEAD_COLUMNS.filter(
    (column) => !['price', 'quantity', 'specificity'].includes(column.value),
).map((column) => {
    if (column.value === 'name') {
        return {
            ...column,
            editable: false,
            isLink: true,
            reactProductDetailEndpoint: true,
        };
    }
    return column;
});

const useProducts = (params) => {
    return useProductsDefault({
        ...params,
        ne_status: undefined,
        FETCH_ACTION: FETCH_DEMANDS,
        _embed: undefined,
    });
};

const ViewMenuItem = (props) => {
    return <ViewMenuItemDefault {...props} reactProductDetailEndpoint detailEndpoint="demands" />;
};

const RowTableMenu = (props) => {
    return (
        <RowTableMenuDefault
            {...props}
            viewMenuItemComponent={ViewMenuItem}
            editMenuItemComponent={() => <></>}
            reviewMenuItemComponent={() => <></>}
        />
    );
};

const TableBody = (props) => {
    return <CustomTableBody {...props} rowTableMenuComponent={RowTableMenu} />;
};

const TableToolbar = (props) => {
    return <TableToolbarDefault {...props} exportCSVcomponent={() => <></>} tableColumnsButtonComponent={() => <></>} />;
};

export default injectToBaseContainer(
    _.flowRight(
        withStyles(styles),
        connect((state) => {
            return {
                pagination: state.productSalableReducer.pagination,
            };
        }),
    ),
    {
        ...defaultConfig,
        listComponent: (props) => (
            <ProductSalableTable
                {...props}
                nameInLocalStorage="demands"
                headColumns={headColumns}
                tableBodyComponent={TableBody}
                tableToolbarComponent={TableToolbar}
                useProducts={useProducts}
            />
        ),
        createComponent: DemandDialog,
        detailEndpoint: 'demands',
        creationType: 'dialog',
        headerTitle: 'Demands table',
        createButtonText: <IntlMessages id="Create demand button" />,
        DELETE_ACTION: DELETE_DEMAND,
    },
);
