import DemandList from './DemandList';
import DemandDetail from './DemandDetail';
import demandsReducer from './DemandReducer';
import { demandsSaga } from './DemandActions';

export const DemandConfig = {
    auth: ['user'],
    routes: [
        {
            component: DemandList,
            path: '/demands',
            exact: true,
        },
        {
            component: DemandDetail,
            path: '/demands/:id',
            exact: true,
        }
    ],
    reducer: demandsReducer,
    actions: demandsSaga,
};
