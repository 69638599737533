import React from 'react';
import {
    Typography,
    FormControlLabel,
    Checkbox,
    Radio,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formControlLabelWrapper: {
        paddingLeft: '21px',
        paddingTop: '12px',
        paddingBottom: '12px',
        borderWidth: '0px 0px 1px 0px',
        borderColor: '#e1e2e1',
        borderStyle: 'solid !important',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#fafafa',
        },
    },
}));

export const FormControlLabelWrapper = ({
    handleChange,
    checkboxValue,
    label,
    isChecked,
    filterName,
    isCheckbox = true,
}) => {
    const classes = useStyles();
    const handleCheckboxChange = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handleChange(
            {
                currentTarget: {
                    name: filterName,
                    value: String(checkboxValue),
                },
            },
            isCheckbox,
        );
    };

    return (
        <div
            className={classes.formControlLabelWrapper}
            onClick={handleCheckboxChange}
        >
            <FormControlLabel
                control={
                    isCheckbox ? (
                        <Checkbox checked={isChecked} />
                    ) : (
                        <Radio checked={isChecked} />
                    )
                }
                label={
                    <Typography onClick={handleCheckboxChange}>
                        {label}
                    </Typography>
                }
            />
        </div>
    );
};
