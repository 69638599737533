import React from 'react';
import _ from 'lodash';
import { Box, Button } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Field, FormikContext, useFormik, useFormikContext } from 'formik';

import { SellerPurchaseDetailsContainer } from 'react-platform-purchase/dist/PurchaseOfSeller/SellerPurchaseDetails/SellerPurchaseDetailsContainer';
import { SellerPurchaseDetails } from 'react-platform-purchase/dist/PurchaseOfSeller/SellerPurchaseDetails/SellerPurchaseDetails';
import { ReservationDetails as ReservationDetailsDefault } from 'react-platform-reservation/dist/ReservationDetailsOfPurchase/ReservationDetails';
import DefaultPurchaseSalableItem from 'react-platform-purchase/dist/PurchaseOfSeller/SellerPurchaseDetails/components/PurchaseSalableItem';
import { ConfirmationDialog } from 'react-platform-components/dist/components/ConfrimationDialog/ConfrimationDialog';
import { IntlMessages } from 'react-platform-components/dist/Translations';
import { purchaseItemStatus } from 'react-platform-purchase/dist/config';
import { renderMultiFilesFieldNew } from 'react-platform-components/dist/uploader';
import { UPLOAD_PURCHASE_FILES } from './PurchaseActions';
import { HANDLE_SELLER_PURCHASE } from 'react-platform-purchase/dist/Purchase/PurchaseActions';
import { DownloadFileButton } from '../PurchaseOfBuyer/PurchaseOfBuyerDetails';

export const PurchaseOfSellerDetailsContainer = () => {
    return (
        <SellerPurchaseDetailsContainer
            listComponent={PurchaseOfSellerDetails}
        />
    );
};

const PurchaseOfSellerDetails = () => {
    return (
        <SellerPurchaseDetails
            reservationDetails={ReservationDetails}
            purchaseSalableItem={PurchaseSalableItem}
        />
    );
};

const ReservationDetails = (props) => {
    return (
        <ReservationDetailsDefault
            {...props}
            getBasicInfo={(basicInfo) =>
                basicInfo.filter((info) => info.id === 'startDate')
            }
        />
    );
};

const UploadFileButton = ({ purchaseItem }) => {
        const formik = useFormikContext();
        const isUploadingInProgress = useSelector(
            ({ purchaseOfSellerReducer }) => purchaseOfSellerReducer.isUploadingInProgress,
        );
        const isSellerPurchaseItemsUpdatingInProgress = useSelector(({ purchaseReducer }) => purchaseReducer.isSellerPurchaseItemsUpdatingInProgress);
        const handleUploadFile = (closeDialog) => {
            formik.handleSubmit();
            formik.setValues({ ...formik.values, callback: closeDialog });
        };
        if (purchaseItem.status !== purchaseItemStatus.WAITING_FOR_EXPEDITION) {
            return null;
        }
        return (
            <Box marginTop="auto">
                <ConfirmationDialog
                    title="Confirm the completion of the purchase"
                    description="Please upload a file to confirm the purchase"
                    disabled={isUploadingInProgress || isSellerPurchaseItemsUpdatingInProgress}
                    onConfirm={handleUploadFile}
                    content={(
                        <Field
                            name="files"
                            label={<IntlMessages id="Upload file (optional)" />}
                            component={renderMultiFilesFieldNew}
                        />
                    )}
                    confirmLabel='confirm_purchase_dialog'
                    cancelLable='cancel_purchase_dialog'
                    component={({ onOpen }) => (
                        <Button
                            color="primary"
                            variant="outlined"
                            size="small"
                            disableElevation
                            startIcon={
                                <CloudUploadIcon style={{ fontSize: 23 }} />
                            }
                            onClick={onOpen}
                        >
                            <IntlMessages id="confirm_purchase" />
                        </Button>
                    )}
                />
            </Box>
        );
    }
;

const PurchaseSalableItem = (props) => {
    const { item } = props;
    const dispatch = useDispatch();
    const purchase = useSelector(
        ({ purchaseReducer }) => purchaseReducer.purchase,
    );
    const formik = useFormik({
        initialValues: {
            files: [],
        },
        onSubmit: (values) => handleSubmit(values),
    });

    const handleConfirmPurchase = () => {
        dispatch({
            type: HANDLE_SELLER_PURCHASE,
            id: purchase.id,
            ids: [item.id],
            model: { status: purchaseItemStatus.DELIVERED },
            onSuccess: () => {
                formik.values.callback();
            },
        });
    };
    const handleSubmit = (values) => {
        if (_.find(values.files || [], (item) => item instanceof File)) {
            const formData = new FormData();
            formData.append('purchase_item', item.id);
            _.forEach(values.files || [], (item) => {
                if (item instanceof File) {
                    formData.append(`files`, item);
                }
            });
            dispatch({
                type: UPLOAD_PURCHASE_FILES,
                id: item.id,
                model: formData,
                onSuccess: () => {
                    handleConfirmPurchase();
                },
            });
        } else {
            handleConfirmPurchase();
        }
    };
    return (
        <FormikContext.Provider value={formik}>
            <DefaultPurchaseSalableItem
                {...props}
                renderExtraContent={ExtraInfo}
                renderExtraButtons={ExtraButtons}
            />
        </FormikContext.Provider>
    );
};

const ExtraButtons = (props) => (
    <>
        <UploadFileButton {...props} />
        <Box marginTop="auto">
            <DownloadFileButton {...props} />
        </Box>
    </>
);

const ExtraInfo = ({ purchaseItem }) => {
    if (_.isEmpty(purchaseItem.salable_item.real_estate_properties))
        return null;
    return (
        <Box
            marginRight={{ xs: 0, sm: '16px' }}
            marginBottom={{ xs: '7px', sm: 0 }}
            display="inline-block"
        >
            <Link to={`/matched-demands/${purchaseItem.salable_item.id}`}>
                <Button
                    variant="text"
                    color="secondary"
                    size="small"
                    classes={{ label: 'underline lowercase' }}
                >
                    <IntlMessages id="show_more" defaultMessage="show_more" />
                </Button>
            </Link>
        </Box>
    );
};
