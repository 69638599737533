import { useState, useEffect } from 'react';
import axios from 'react-platform-components/dist/axios';
import _ from 'lodash';
import { BASE_API_URL } from 'react-platform-components/dist/config';

export const CATEGORY_OF_PRODUCT_API_END_POINT = 'category-of-products';

// this variable is to save our state globally
let _categories = null;
export const useCategoryFilter = ({ categories, categoriesParents }) => {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [organizedCategories, setOrganizedCategories] = useState(
        _categories || categoriesParents,
    );

    // we use this effect for setParentsCategories to organizedCategories just one times in the first dialog opening
    useEffect(() => {
        if (_.isEmpty(_categories)) {
            setOrganizedCategories(categoriesParents);
        }
    }, [JSON.stringify(categoriesParents)]);

    const handleGoForward = async (category) => {
        // detect if category is fetched for not sending a request again
        const isCategoryFetched = category.parent_category_id
            ? category.isFetched
            : organizedCategories.find((cat) => cat.id === category.id)
                  ?.isFetched;
        if (_.isEmpty(categories) && !isCategoryFetched) {
            // get child category of parent category.id
            const params = {
                parent_category_id: category.id,
                _fields: [
                    'deep_category_specificities',
                    'id',
                    'is_final',
                    'parent_category_id',
                    'sub_categories',
                    'name',
                    'deep_category_variants',
                    'deep_category_options',
                ],
            };
            const response = await axios.get(
                `${BASE_API_URL}${CATEGORY_OF_PRODUCT_API_END_POINT}`,
                { params },
            );

            // get index of updated category to add isFetched: true in the parent category
            const indexOfupdatedCategory = organizedCategories.findIndex(
                (cat) => cat.id === category.id,
            );
            if (indexOfupdatedCategory !== -1) {
                const updatedCategory = {
                    ...organizedCategories[indexOfupdatedCategory],
                    isFetched: true,
                };
                // add new child category getting from request
                const newOrganizedCategories = [
                    ...organizedCategories.slice(0, indexOfupdatedCategory),
                    updatedCategory,
                    ...organizedCategories.slice(indexOfupdatedCategory + 1),
                    ...response.data,
                ];
                setOrganizedCategories(newOrganizedCategories);
                _categories = newOrganizedCategories;
            } else {
                setOrganizedCategories([
                    ...organizedCategories,
                    ...response.data,
                ]);
                _categories = [...organizedCategories, ...response.data];
            }

            // we need to get subCategories from the response.data because in subCategories we only have the id and name
            category = {
                ...category,
                sub_categories: response.data,
            };
            setSelectedCategory(category);
        } else {
            // we need to get subCategories from the categories because in subCategories we only have the id and name
            category = {
                ...category,
                sub_categories:
                    (_.isEmpty(categories)
                        ? organizedCategories
                        : categories
                    )?.filter((cat) => cat.parent_category_id == category.id) ||
                    null,
            };
            setSelectedCategory(category);
        }
    };

    const handleGoBackward = () => {
        // here we get the parent of all child categories
        const previousCategory = (
            _.isEmpty(categories) ? organizedCategories : categories
        ).find((cat) => cat.id == selectedCategory.parent_category_id);
        // we need to get subCategories from the categories because in subCategories we only have the id and name
        const CategoryEmbedsubCategories = previousCategory
            ? {
                  ...previousCategory,
                  sub_categories: (_.isEmpty(categories)
                      ? organizedCategories
                      : categories
                  ).filter(
                      (organized) =>
                          previousCategory.id == organized.parent_category_id,
                  ),
              }
            : null;
        setSelectedCategory(CategoryEmbedsubCategories);
    };
    return {
        selectedCategory,
        setSelectedCategory,
        handleGoBackward,
        handleGoForward,
        organizedCategories,
        setOrganizedCategories,
    };
};

export const useAnimation = (time = 400) => {
    const [animate, setAnimate] = useState('');
    useEffect(() => {
        const timeout = setTimeout(() => {
            setAnimate('');
        }, time);
        return () => {
            clearTimeout(timeout);
        };
    }, [animate]);
    return { animate, setAnimate };
};
