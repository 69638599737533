import { CREATE_DEMAND_ERROR, CREATE_DEMAND_START, CREATE_DEMAND_SUCCESS } from "./DemandActions";

const defaultState = {
    isCreateDemandInProgress: false,
};
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case CREATE_DEMAND_START: {
            return { ...state, isCreateDemandInProgress: true };
        }
        case CREATE_DEMAND_SUCCESS: {
            return { ...state, isCreateDemandInProgress: false };
        }
        case CREATE_DEMAND_ERROR: {
            return { ...state, isCreateDemandInProgress: false };
        }
        default: {
            return state;
        }
    }
}
