import _ from 'lodash';
import React from 'react';
import { all, call, takeEvery } from 'redux-saga/effects';
import { generateRoutesFromConfigs } from 'react-platform-components/dist/helpers';
import { IntlMessages } from 'react-platform-components/dist/Translations';
import { LocaleConfig } from 'react-platform-components/dist/Translations/TranslationsConfig';
import { LoginConfig } from 'react-platform-user/dist/Login/LoginConfig';
import { ProfileConfig } from 'react-platform-user/dist/Profile/ProfileConfig';
import { RegisterConfig } from 'react-platform-user/dist/Register/RegisterConfig';
import { ForgotPasswordConfig } from 'react-platform-user/dist/ForgotPassword/ForgotPasswordConfig';
import { DashboardConfig } from './containers/Dashboard/DashboardConfig';
import { ExternalPageConfig } from "react-platform-components/dist/ExternalPage/ExternalPageConfig"
import { CategoryOfProductConfig } from 'react-platform-category-of-product/dist/CategoryOfProduct/CategoryOfProductConfig';
import { ProductConfig } from 'react-platform-product/dist/Product/ProductConfig';
import { ProductDetailsConfig } from 'react-platform-product/dist/ProductDetails/ProductDetailsConfig';
import { AddressOfProductConfig } from 'react-platform-address-of-product/dist/AddressOfProduct/AddressOfProductConfig';
import { StoreConfig } from 'react-platform-store/dist/Store/StoreConfig';
import { StoreDetailsConfig } from 'react-platform-store/dist/Store/StoreDetailsConfig';
import { RegisterWithInlinesConfig } from 'react-platform-user/dist/RegisterWithInlines/RegisterWithInlinesConfig'
import { AddressConfig } from 'react-platform-address/dist/Address/AddressConfig';
import { AddressOfUserConfig } from "react-platform-address-of-user/dist/AddressOfUser/AddressOfUserConfig";
import { SpecificitiesOfProductConfig } from 'react-platform-specificity-of-product/dist/SpecificityOfProduct/SpecificitiesOfProductConfig';
import { BookmarkOfStoreConfig } from 'react-platform-bookmark-of-store/dist/BookmarkOfStore/BookmarkOfStoreConfig'
import { BookmarkOfProductConfig } from "react-platform-bookmark-of-product/dist/BookmarkOfProduct/BookmarkOfProductConfig"
import { ChatConfig, ConversationConfig } from "react-platform-chat/dist/Chat/ChatConfig";
import { ProductFeaturingConfig } from "react-platform-productfeature/dist/ProductFeaturing/ProductFeaturingConfig"
import { SpecificitiesOfStoreConfig } from "react-platform-specificity-of-store/dist/SpecificityOfStore/SpecificitiesOfStoreConfig"
import { CreatedReviewOfProductConfig } from "react-platform-review-of-product/dist/ReviewOfProduct/Created/CreatedReviewOfProductConfig"
import { RecievedReviewOfProductConfig } from "react-platform-review-of-product/dist/ReviewOfProduct/Recieved/RecievedReviewOfProductConfig"
import { MenuConfig } from 'react-platform-components/dist/Menu/MenuConfig';
import { NotificationsConfig } from 'react-platform-components/dist/Notifications/NotificationsConfig';
import { CategoryOfStoreConfig } from 'react-platform-category-of-store/dist/CategoryOfStore/CategoryOfStoreConfig';
import { PurchaseOfBuyerConfig } from "./containers/PurchaseOfBuyer/PurchaseOfBuyerConfig";
import { PurchaseOfSellerConfig } from "./containers/PurchaseOfSeller/PurchaseOfSellerConfig";



import { PurchaseConfig } from 'react-platform-purchase/dist/Purchase/PurchaseConfig';
import { ShoppingCartConfig } from './containers/ShoppingCart/ShoppingCartConfig';
import { TransactionsConfig } from 'react-platform-transactions/dist/Transactions/TransactionsConfig';
import { PurchaseResultConfig } from "react-platform-purchase/dist/Purchase/pages/PurchaseResultConfig";
import { ServiceCreationConfig } from './containers/Services/ServiceCreationConfig';
import { MatchedDemandsConfig } from './containers/MatchedDemands/MatchedDemandsConfig';
import { CreateRealEstateConfig } from './containers/CreateRealEstate/CreateRealEstateConfig';
import { DemandConfig } from './containers/Demand/DemandConfig';
import { RealEstateListConfig } from './containers/RealEstateList/RealEstateListConfig';
import { CreateProductSalableConfig } from 'react-platform-productsalable/dist/CreateProductSalable/CreateProductSalableConfig';
import { ServicesListConfig } from './containers/Services/ServicesListConfig';
import { ProductSalableConfig } from 'react-platform-productsalable/dist/ProductSalable/ProductSalableConfig';
import { PropositionConfig } from './containers/Proposition/PropositionConfig';
import { OptionOfProductConfig } from 'react-platform-option-of-product/dist/OptionOfProduct/OptionOfProductConfig';
import { PurchaseItemsReviewsConfig } from "react-platform-reviewofpurchaseitem/dist/Review/ReviewsConfig"
import { ReservationConfig } from "react-platform-reservation/dist/Reservation/ReservationConfig"
import { StripeConnectPagesConfig } from "react-platform-stripeconnect/dist/pages/StripeConnectPagesConfig"



const routeConfigs = [
    MenuConfig,
    NotificationsConfig,
    DashboardConfig,
    LoginConfig,
    ForgotPasswordConfig,
    ProfileConfig,
    ProductConfig,
    ProductDetailsConfig,
    StoreConfig,
    StoreDetailsConfig,
    RegisterWithInlinesConfig,
    BookmarkOfStoreConfig,
    BookmarkOfProductConfig,
    ChatConfig,
    ConversationConfig,
    RecievedReviewOfProductConfig,
    CreatedReviewOfProductConfig,
    ExternalPageConfig,
    PurchaseOfBuyerConfig,
    PurchaseOfSellerConfig,
    DemandConfig,
    RealEstateListConfig,
    CreateRealEstateConfig,
    ServiceCreationConfig,
    MatchedDemandsConfig,
    ServicesListConfig,
    PropositionConfig,
    TransactionsConfig,
    ShoppingCartConfig,
    PurchaseResultConfig,
    StripeConnectPagesConfig,
    
];


export const navigationConfig = [
    {
        'auth': DashboardConfig.auth,
        'icon': 'dashboard',
        'id': 'dashboard-component',
        'title': <IntlMessages id="Dashboard" defaultMessage="Dashboard" />,
        'type': 'item',
        'url': '/dashboard'
    },
    {
        'auth': ProductConfig.auth,
        'icon': 'assignment_ind',
        'id': 'product-component',
        'title': <IntlMessages id="Mes Produits" defaultMessage="Mes Produits" />,
        'type': 'item',
        'url': '/products'
    },

    {
        'auth': StoreConfig.auth,
        'icon': 'assignment_ind',
        'id': 'store-component',
        'title': <IntlMessages id="Store" defaultMessage="Store" />,
        'type': 'item',
        'url': '/store'
    },
    {
        'auth': BookmarkOfStoreConfig.auth,
        'icon': 'favorite',
        'id': 'bookmarks-of-store',
        'title': <IntlMessages id="Stores bookmarks" defaultMessage="Stores bookmarks" />,
        'type': 'item',
        'url': '/store-bookmarks'
    },
    {
        auth: BookmarkOfProductConfig.auth,
        id: "product-bookmark-component",
        title: <IntlMessages id="Products bookmarks" defaultMessage="Products bookmarks" />,
        type: "item",
        url: "/product-bookmarks",
    },

];


export const routes = [
    ...generateRoutesFromConfigs(routeConfigs),
    {
        // eslint-disable-next-line react/display-name
        auth: ['user'],
        component: () => <div />,
        path: '/',
    }
];


function* redirect(action) {
    yield call(action.history.push, action.to)
}

function* sagas() {
    yield all([
        takeEvery('REDIRECT', redirect)
    ])
}


export function* rootSaga() {
    yield all([
        sagas(),
        LocaleConfig.actions(),
        LoginConfig.actions(),
        RegisterConfig.actions(),
        ForgotPasswordConfig.actions(),
        ProfileConfig.actions(),
        CategoryOfProductConfig.actions(),
        SpecificitiesOfProductConfig.actions(),
        ProductConfig.actions(),
        ProductDetailsConfig.actions(),
        AddressOfProductConfig.actions(),
        AddressConfig.actions(),
        AddressOfUserConfig.actions(),
        StoreConfig.actions(),
        RegisterWithInlinesConfig.actions(),
        SpecificitiesOfStoreConfig.actions(),
        BookmarkOfStoreConfig.actions(),
        BookmarkOfProductConfig.actions(),
        ChatConfig.actions(),
        ProductFeaturingConfig.actions(),
        CreatedReviewOfProductConfig.actions(),
        CategoryOfStoreConfig.actions(),
        DemandConfig.actions(),
        RealEstateListConfig.actions(),
        MatchedDemandsConfig.actions(),
        ProductSalableConfig.actions(),
        PropositionConfig.actions(),
        OptionOfProductConfig.actions(),
        TransactionsConfig.actions(),
        ShoppingCartConfig.actions(),
        PurchaseConfig.actions(),
        PurchaseOfSellerConfig.actions(),
        PurchaseItemsReviewsConfig.actions(),
        ReservationConfig.actions(),
        ServicesListConfig.actions(),
                
    ])
}



function errorMessage(state = { error: null }, action) {

    switch (action.type) {
        case "HANDLE_ERROR": {
            return { ...state, error: action.error }
        }
        case "CLEAR_ERROR": {
            return { ...state, error: null }
        }
        default: return { ...state }
    }
}

function successMessage(state = { message: null }, action) {

    switch (action.type) {
        case "HANDLE_SUCCESS_MESSAGE": {
            return { ...state, message: action.message }
        }
        case "CLEAR_SUCCESS_MESSAGE": {
            return { ...state, message: null }
        }
        default: return { ...state }
    }
}

function auth(state = { roles: ['guest'] }, action) {

    switch (action.type) {
        case 'USER_ALREAY_AUTHENTICATED': {

            const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
            return {
                user: {
                    ...user,
                    roles: user.roles.map(role => role.name)
                }
            }

        }
        case "FETCH_USER_START": {
            return { ...state }
        }
        case "FETCH_USER_ERROR": {
            return { ...state }
        }
        case "FETCH_USER_SUCCESS": {
            return {
                user: {
                    ...action.payload,
                    roles: action.payload.roles.map(role => role.name)
                }
            }
        }
        default: {
            if (state.user?.id) {
                return { ...state }
            }
            const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
            return {
                user: {
                    ...user,
                    roles: user ? user.roles.map(role => role.name) : ['guest']
                }
            }
        }
    }
};

const defaultSettings = {
    layout: {
        config: {
            scroll: 'content',
            navbar: {
                display: true,
                folded: false,
                position: 'left'
            },
            toolbar: {
                display: false,
                style: 'fixed',
                position: 'below'
            },
            footer: {
                display: true,
                style: 'fixed',
                position: 'below'
            },
        }
    }
}

const initialState = {
    defaults: _.merge({}, defaultSettings),
    current: _.merge({}, defaultSettings)
}

function settings(state = initialState, action) {
    switch (action.type) {
        case 'SET_SETTINGS': {
            return {
                ...state,
                current: _.merge({}, action.value)
            }
        }
        case 'SET_DEFAULT_SETTINGS': {
            return {
                ...state,
                defaults: _.merge({}, state.defaults, action.value),
                current: _.merge({}, state.defaults, action.value)
            }
        }
        case 'RESET_DEFAULT_SETTINGS': {
            return {
                ...state,
                defaults: _.merge({}, state.defaults),
                current: _.merge({}, state.defaults)
            }
        }
        default: {
            return state;
        }
    }
}


export const reducers = {
    auth,
    errorMessage,
    successMessage,
    settings,
    localeReducer: LocaleConfig.reducer,
    loginReducer: LoginConfig.reducer,
    registerReducer: RegisterConfig.reducer,
    forgotPasswordReducer: ForgotPasswordConfig.reducer,
    profileReducer: ProfileConfig.reducer,
    categoryOfProductReducer: CategoryOfProductConfig.reducer,
    specificityOfProductReducer: SpecificitiesOfProductConfig.reducer,
    ProductReducer: ProductConfig.reducer,
    ProductDetailsReducer: ProductDetailsConfig.reducer,
    addressOfProductReducer: AddressOfProductConfig.reducer,
    addressReducer: AddressConfig.reducer,
    addressesOfUserReducer: AddressOfUserConfig.reducer,
    storeReducer: StoreConfig.reducer,
    registerWithInlinesReducer: RegisterWithInlinesConfig.reducer,
    specificityOfStoreReducer: SpecificitiesOfStoreConfig.reducer,
    bookmarksReducer: BookmarkOfStoreConfig.reducer,
    bookmarkOfProductReducer: BookmarkOfProductConfig.reducer,
    chatReducer: ChatConfig.reducer,
    productFeaturingsReducer: ProductFeaturingConfig.reducer,
    productReviewsReducer: CreatedReviewOfProductConfig.reducer,
    categoryOfStoreReducer: CategoryOfStoreConfig.reducer,
    
    demandReducer: DemandConfig.reducer,
    productSalableReducer: ProductSalableConfig.reducer,
    propositionReducer: PropositionConfig.reducer,
    optionOfProductReducer: OptionOfProductConfig.reducer,
    transactionsReducer: TransactionsConfig.reducer,
    shoppingCartReducer: ShoppingCartConfig.reducer,
    purchaseReducer: PurchaseConfig.reducer,
    purchaseOfSellerReducer: PurchaseOfSellerConfig.reducer,
    purchaseItemsReviewsReducer: PurchaseItemsReviewsConfig.reducer,
    reservationReducer: ReservationConfig.reducer,
    servicesReducer: ServicesListConfig.reducer,
    
}
