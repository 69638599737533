import React from 'react';
import ShoppingCartDefault from 'react-platform-shoppingcart/dist/NewShoppingCart/ShoppingCartContainer';
import { SellerCartItems as SellerCartItemsDefault } from 'react-platform-shoppingcart/dist/NewShoppingCart/components/SellerCartItems';
import { ReservationDetails as ReservationDetailsDefault } from 'react-platform-reservation/dist/ReservationDetailsOfShoppingCart/ReservationDetails';

export const ShoppingCart = (props) => {
    return <ShoppingCartDefault {...props} extraProps={{
        hasOptionOfProductStep: true,
        generalStepExtraProps: {
            sellerCartItemsComponent: SellerCartItems,
        },
    }} />;
};

const SellerCartItems = (props) => {
    return <SellerCartItemsDefault {...props} reservationDetailsComponent={ReservationDetails} />;
};

const ReservationDetails = (props) => {
    return <ReservationDetailsDefault {...props} getBasicInfo={(basicInfo) => basicInfo.filter(info => info.id === 'startDate')} />;
};