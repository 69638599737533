import DemandDetail from '../Demand/DemandDetail';
import MatchedDemands from './MatchedDemands';
import { matchedDemandsSaga } from './MatchedDemandsActions';

export const MatchedDemandsConfig = {
    auth: ['user'],
    routes: [
        {
            component: MatchedDemands,
            path: '/matched-demands',
            exact: true,
        },
        {
            component: DemandDetail,
            path: '/matched-demands/:id',
            exact: true,
        }
    ],
    settings: {
        routeSuffix: 'matched-demands'
    },
    actions: matchedDemandsSaga,
};
