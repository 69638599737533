import { CreateRealEstate } from "./CreateRealEstate";

export const CreateRealEstateConfig = {
    auth: ["user"],
    routes: [
        {
            component: CreateRealEstate,
            path: "/create-real-estate",
            exact: true,
        },
        {
            component: CreateRealEstate,
            path: "/create-real-estate/:id",
            exact: true,
        },
    ],
    settings: {
        routeSuffix: 'real-estate',
        layout: {
            config: {
                topNavigation: {
                    display: true,
                    goBackTo: '/real-estates',
                    icon: 'clear',
                },
                bottomNavigation: false

            }
        }
    },
};
