import _ from 'lodash';
import { all, put, takeEvery } from 'redux-saga/effects';
import axios from 'react-platform-components/dist/axios';
import { BASE_API_URL } from 'react-platform-components/dist/config';

export const FETCH_CATEGORIES_OF_SERVICE = 'FETCH_CATEGORIES_OF_SERVICE';
export const FETCH_CATEGORIES_OF_SERVICE_START = 'FETCH_CATEGORIES_OF_SERVICE_START';
export const FETCH_CATEGORIES_OF_SERVICE_SUCCESS =
    'FETCH_CATEGORIES_OF_SERVICE_SUCCESS';
export const FETCH_CATEGORIES_OF_SERVICE_FAILURE =
    'FETCH_CATEGORIES_OF_SERVICE_FAILURE';

export function* fetchCategoriesOfService(action) {
    yield put({
        type: FETCH_CATEGORIES_OF_SERVICE_START,
    });
    try {
        const params = action.params || {};
        const response = yield axios.get(
            `${BASE_API_URL}category-of-products`,
            {
                params,
            },
        );
        yield put({
            type: FETCH_CATEGORIES_OF_SERVICE_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        let errorMessage = 'Something went wrong';
        const errors = _.get(error, 'response.data.form_errors');

        if (errors) {
            errorMessage = _.values(errors).join('\n');
        }
        yield all([
            put({
                type: FETCH_CATEGORIES_OF_SERVICE_FAILURE,
            }),
            put({ type: 'HANDLE_ERROR', error: errorMessage }),
        ]);
    }
}

export function* categoryOfServiceSaga() {
    yield all([
        takeEvery(FETCH_CATEGORIES_OF_SERVICE, fetchCategoriesOfService),
    ]);
}
