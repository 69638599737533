import RealEstateListContainer from './RealEstateList';
import { realEstatesSaga } from './RealEstateListActions';

export const RealEstateListConfig = {
    auth: ['user'],
    routes: [
        {
            component: RealEstateListContainer,
            path: '/real-estates',
        },
    ],
    settings: {
        routeSuffix: 'real-estate',
    },
    actions: realEstatesSaga,
};
