import { all, put, takeEvery } from 'redux-saga/effects';

export const FETCH_MATCHED_DEMANDS = 'FETCH_MATCHED_DEMANDS';
export const FETCH_MATCHED_DEMAND = 'FETCH_MATCHED_DEMAND';

export function* fetchMatchedDemands(action) {
    const params = action.params || {}
    const sortParam =
        params._sort &&
        (params._sort.startsWith('-')
            ? params._sort.replace('-', '-demand.')
            : `demand.${params._sort}`);
    yield put({
        ...action,
        params: {
            ...params,
            _sort: sortParam,
        },
        type: 'FETCH_PRODUCTS',
        endpoint: 'matched-demands',
    });
}

export function* fetchMatchedDemand(action) {
    yield put({
        ...action,
        type: 'FETCH_PRODUCT',
        endpoint: 'md-based-on-demands',
    });
}

export function* matchedDemandsSaga() {
    yield all([
        takeEvery(FETCH_MATCHED_DEMANDS, fetchMatchedDemands),
        takeEvery(FETCH_MATCHED_DEMAND, fetchMatchedDemand),
    ]);
}
