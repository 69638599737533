import React from 'react';
import _ from 'lodash';
import { FormikContext, useFormik, useFormikContext } from 'formik';
import {
    Box,
    Button,
    Checkbox as CheckboxMUI,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    ListItemText,
    MenuItem,
    useMediaQuery,
} from '@material-ui/core';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Select, TextField } from 'react-platform-components/dist/form-fields';
import { IntlMessages } from 'react-platform-components/dist/Translations';
import { ImagesField } from 'react-platform-productsalable/dist/CreateProductSalable/forms/steps/InformationStep';
import { CategoryOfProductField } from 'react-platform-category-of-product/dist/CategoryOfProduct/CategoryOfProductField/CategoryOfProductField';
import { setImagesValuesToFormData } from 'react-platform-productsalable/dist/CreateProductSalable/forms/CreateProductSalableForm';
import { CREATE_DEMAND, FETCH_DEMANDS } from './DemandActions';
import { required } from 'react-platform-components/dist/form-validators';
import { PRODUCTS_LIST_FILTERS } from 'react-platform-product/dist/config';
import { FETCH_CATEGORIES_OF_SERVICE } from '../Services/ServicesActions';

export const DemandDialog = ({ product, ...props }) => {
    const history = useHistory();
    const [open, setOpen] = React.useState(props.open || false);
    const fullScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const formik = useFormik({
        initialValues: {
            real_estate_properties: _.isEmpty(product) ? [] : [product.id],
            name: '',
            category: '',
            description: '',
            images: [],
        },
        onSubmit: (values) => handleSubmit(values),
    });
    const dispatch = useDispatch();
    const { isCreateDemandInProgress } = useSelector(
        ({ demandReducer }) => demandReducer,
    );
    const realEstates = useSelector(
        ({ ProductReducer }) => ProductReducer.products,
    );
    const inProgress = useSelector(
        ({ ProductReducer }) => ProductReducer.isProductsFetchingInProgress,
    );

    React.useEffect(() => {
        if (_.isEmpty(product)) {
            dispatch({
                type: 'FETCH_REAL_ESTATES',
                params: {
                    _fields: ['id', 'name', 'category', 'address'],
                    ne_status: PRODUCTS_LIST_FILTERS
                },
            });
        }
    }, []);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        props.close && props.close();
    };

    const handleSubmit = (data) => {
        const firstRealEstate = realEstates.find(realEstate => +realEstate.id === +data.real_estate_properties[0])
        const address = Object.entries(firstRealEstate.address).reduce((acc, [key, value]) => {
            return {
                ...acc,
                [`address-${key}`]: value,
            };
        }, {})
        const values = {
            ...data,
            ...address,
        }
        const formData = new FormData();
        const excludedFields = ['images'];
        _.forOwn(values, (value, key) => {
            if (excludedFields.indexOf(key) !== -1) {
                return;
            } else if (value !== undefined && value !== null) {
                formData.append(key, value);
            }
        });
        setImagesValuesToFormData(formData, 'images', values);
        dispatch({
            type: CREATE_DEMAND,
            model: formData,
            onSuccess: () => {
                if (_.isEmpty(product)) {
                    dispatch({ type: FETCH_DEMANDS });
                    handleClose();
                } else {
                    history.push('/demands');
                }
            },
        });
    };

    const hasRealEstates = !_.isEmpty(realEstates) || !_.isEmpty(product)
    return (
        <>
            {!props.open && (
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClick}
                    >
                        <IntlMessages id="Create demand button" />
                    </Button>
                </Grid>
            )}
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
                fullScreen={fullScreen}
            >
                <DialogTitle>
                    <IntlMessages id="Create demand dialog title" />
                </DialogTitle>
                <DialogContent>
                    {inProgress ? (
                        <Box padding={2} display="flex" justifyContent="center">
                            <CircularProgress />
                        </Box>
                    ) : hasRealEstates ? (
                        <FormikContext.Provider value={formik}>
                            <form noValidate onSubmit={formik.handleSubmit}>
                                <DemandForm product={product} />
                            </form>
                        </FormikContext.Provider>
                    ) : (
                        <DialogContentText>
                            <IntlMessages id="You must create at least one real estate before creating a demand" />
                        </DialogContentText>
                    )}
                </DialogContent>
                {!inProgress && (
                    <DialogActions>
                        {hasRealEstates && (
                            <Button
                                onClick={handleClose}
                                color="primary"
                                disabled={isCreateDemandInProgress}
                            >
                                <IntlMessages id="Cancel demand form button" />
                            </Button>
                        )}
                        {hasRealEstates ? (
                            <Button
                                onClick={formik.handleSubmit}
                                color="primary"
                                variant="contained"
                                disabled={isCreateDemandInProgress}
                            >
                                <IntlMessages id="Create demand form button" />
                            </Button>
                        ) : (
                            <Button
                                component={Link}
                                to="/create-real-estate"
                                color="primary"
                                variant="contained"
                            >
                                <IntlMessages id="go to real estate creation page" />
                            </Button>
                        )}
                    </DialogActions>
                )}
            </Dialog>
        </>
    );
};

const DemandForm = ({ product }) => {
    const formik = useFormikContext();
    const realEstates = useSelector(
        ({ ProductReducer }) => ProductReducer.products,
    );
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch({ type: FETCH_CATEGORIES_OF_SERVICE });
    }, []);

    return (
        <>
            <Select
                variant="outlined"
                fullWidth
                required
                validate={required()}
                multiple
                label="Real Estate Demand"
                name="real_estate_properties"
                margin="normal"
                allValues={realEstates.map((realEstate) => realEstate.id)}
                renderValue={(selectedValues) =>
                    selectedValues
                        .map(
                            (value) =>
                                realEstates?.find(
                                    (realEstate) => realEstate.id === value,
                                )?.name,
                        )
                        .join(', ')
                }
            >
                {realEstates.map((realEstate) => (
                    <MenuItem key={realEstate.id} value={realEstate.id}>
                        <CheckboxMUI
                            checked={formik.values.real_estate_properties?.includes(
                                realEstate.id,
                            )}
                        />
                        <ListItemText primary={realEstate.name} />
                    </MenuItem>
                ))}
            </Select>
            <TextField
                name="name"
                label="Name Demand"
                margin="normal"
                variant="outlined"
                fullWidth
                required
                validate={required()}
            />
            <CategoryOfProductField values={formik.values} required reducerName='servicesReducer' />
            <TextField
                name="description"
                label="Description Demand"
                margin="normal"
                variant="outlined"
                multiline
                rows={7}
                fullWidth
            />
            <ImagesField values={formik.values} label="Images Demand" />
        </>
    );
};
