import { ShoppingCartConfig as ShoppingCartConfigDefault } from 'react-platform-shoppingcart/dist/NewShoppingCart/ShoppingCartConfig';
import { ShoppingCart } from './ShoppingCart';

export const ShoppingCartConfig = {
    ...ShoppingCartConfigDefault,
    routes: ShoppingCartConfigDefault.routes.map((route) => ({
        ...route,
        component: ShoppingCart,
    })),
};
