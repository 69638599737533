import { getBackendConfig } from "react-platform-components/dist/Utils"

export const BASE_API_URL = getBackendConfig('REACT_BASE_API_URL', process.env.REACT_APP_BASE_API_URL)
export const BASE_URL = getBackendConfig('REACT_BASE_URL', process.env.REACT_APP_BASE_URL)

export const STATIC_PATH = process.env.PUBLIC_URL || ''
export const BASE_APP_URL = process.env.REACT_APP_BASE_APP_URL
export const LOGIN_PAGE = `${BASE_URL}${BASE_APP_URL}/login`





export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
export const ENV = getBackendConfig("ENV", "dev");