import React from 'react';
import { Grid } from '@material-ui/core';

import { CreateProductSalable } from 'react-platform-productsalable/dist/CreateProductSalable/forms/CreateProductSalable';
import { CreateProductSalableForm, getFormInitialValuesKeys } from 'react-platform-productsalable/dist/CreateProductSalable/forms/CreateProductSalableForm';
import { AddressOfProductFields as AddressOfProductFieldsDefault } from 'react-platform-productsalable/dist/CreateProductSalable/forms/steps/AddressOfProductStep';
import { TextField } from 'react-platform-components/dist/form-fields';
import { required } from 'react-platform-components/dist/form-validators';

export const ServiceCreation = () => {
    return (
        <CreateProductSalable createProductSalableForm={ServiceCreationForm} />
    );
};

export const ServiceCreationForm = (props) => {
    return (
        <CreateProductSalableForm
            {...props}
            isOptionOfProductStepEnabled
            getStepsContent={(defaultSteps) => {
                return defaultSteps.map((step) => {
                    if (step.label === 'Address step') {
                        return {
                            ...step,
                            component: AddressOfProductFields,
                        };
                    }
                    return step;
                });
            }}
            getFormInitialValuesKeys={(props) => {
                return getFormInitialValuesKeys(props).concat('radius');
            }}
        />
    );
};

export const AddressOfProductFields = (props) => {
    return (
        <AddressOfProductFieldsDefault
            {...props}
            extraFields={
                <Grid item xs={6}>
                    <TextField
                        name="radius"
                        label="Radius"
                        type="number"
                        margin="normal"
                        fullWidth
                        validate={required()}
                        required
                    />
                </Grid>
            }
        />
    );
};
