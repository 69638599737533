import { ServiceCreation } from './ServiceCreation';

export const ServiceCreationConfig = {
    auth: ['user'],
    routes: [
        {
            component: ServiceCreation,
            path: '/create-product-salable',
            exact: true,
        },
        {
            component: ServiceCreation,
            path: '/create-product-salable/:id',
            exact: true,
        },
    ],
    settings: {
        layout: {
            config: {
                topNavigation: {
                    display: true,
                    goBackTo: '/product-salable',
                    icon: 'clear',
                },
                bottomNavigation: false,
            },
        },
    },
};
