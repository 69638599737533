import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import _ from 'lodash';
import { injectToBaseContainer } from 'react-platform-components/dist/BaseComponents/BaseInjectors';
import { styles } from 'react-platform-product/dist/Product/ProductSyles';
import { connect } from 'react-redux';
import { defaultConfig } from 'react-platform-productsalable/dist/ProductSalable/ProductSalableContainer';
import ProductSalableTable from 'react-platform-productsalable/dist/ProductSalable/ProductSalableTable';
import { useProductsDefault } from 'react-platform-productsalable/dist/ProductSalable/tableHooks';
import { TABLE_HEAD_COLUMNS } from 'react-platform-productsalable/dist/ProductSalable/ProductSalableTable';
import { CustomTableBody } from 'react-platform-productsalable/dist/ProductSalable/tableComponents/CustomTableBody';
import {
    RowTableMenu as RowTableMenuDefault,
    ViewMenuItemDefault,
} from 'react-platform-productsalable/dist/ProductSalable/tableComponents/bodyComponents/RowTableMenu';
import { FETCH_PROPOSITIONS } from 'react-platform-proposition/dist/Proposition/PropositionActions';

const headColumns = TABLE_HEAD_COLUMNS.filter(
    (column) => !['quantity', 'images', 'specificity'].includes(column.value),
).map((column) => {
    if (column.value === 'name') {
        return {
            ...column,
            editable: false,
            value: 'product.name',
            isLink: true,
            reactProductDetailEndpoint: true,
        };
    } else if (column.value === 'id') {
        return {
            ...column,
            value: '_id',
        };
    } else if (column.value === 'category.name') {
        return {
            ...column,
            value: 'product.category.name',
        };
    }
    return {
        ...column,
        editable: false,
    };
});

const useProducts = (params) => {
    return useProductsDefault({
        ...params,
        FETCH_ACTION: FETCH_PROPOSITIONS,
        _embed: 'product',
        type_of_proposition: 'received',
        _sort: '-created_at',
        ne_status: undefined
    });
};

const TableToolbar = (props) => {
    return <></>;
};

const ViewMenuItem = (props) => {
    return (
        <ViewMenuItemDefault {...props} getCustomLink={(product) => `demands/${product.id}`} />
    );
};

const RowTableMenu = (props) => {
    return (
        <RowTableMenuDefault
            {...props}
            viewMenuItemComponent={ViewMenuItem}
            editMenuItemComponent={() => <></>}
            reviewMenuItemComponent={() => <></>}
            deleteMenuItemComponent={() => <></>}
        />
    );
};

const TableBody = (props) => {
    return <CustomTableBody {...props} rowTableMenuComponent={RowTableMenu} />;
};

export default injectToBaseContainer(
    _.flowRight(
        withStyles(styles),
        connect((state) => {
            return {
                pagination: state.propositionReducer.pagination,
            };
        }),
    ),
    {
        ...defaultConfig,
        listComponent: (props) => (
            <>
            <Box marginTop={2} />
            <ProductSalableTable
                {...props}
                getReducers={(state) => ({
                    products: state.propositionReducer.propositions.map(
                        (proposition) => {
                            return {
                                ...proposition,
                                _id: proposition.id,
                                id: proposition.product.id,
                            };
                        },
                    ),
                    inProgress:
                        state.propositionReducer
                            .isFetchingPropositionsInProgress,
                    pagination: state.propositionReducer.pagination,
                })}
                nameInLocalStorage="propositions"
                headColumns={headColumns}
                tableBodyComponent={TableBody}
                tableToolbarComponent={TableToolbar}
                useProducts={useProducts}
            />
            </>
        ),
        detailEndpoint: 'matched-demands',
        headerTitle: 'Propositions',
        creationButton: () => <></>,
    },
);
