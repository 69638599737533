import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Box,
    Button,
    CircularProgress,
    Dialog,
    Grid,
    Icon,
    ImageList,
    ImageListItem,
    Paper,
    Typography,
    makeStyles,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import ReactLoading from 'react-loading';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import {
    IntlMessages,
    IntlNumber,
} from 'react-platform-components/dist/Translations';
import _ from 'lodash';
import { FETCH_DEMAND } from './DemandActions';
import { FETCH_PROPOSITIONS } from 'react-platform-proposition/dist/Proposition/PropositionActions';
import AcceptPropositionDialog from 'react-platform-proposition/dist/Proposition/components/AcceptPropositionDialog';
import { AddPropositionDialog } from 'react-platform-proposition/dist/Proposition/components/AddPropositionDialog';
import { BASE_URL } from 'react-platform-components/dist/config';
import { urlJoin } from 'react-platform-components/dist/helpers';
import { FETCH_MATCHED_DEMAND } from '../MatchedDemands/MatchedDemandsActions';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    demandDescription: { marginTop: theme.spacing(1) },
    paper: {
        margin: theme.spacing(4),
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1),
            padding: theme.spacing(2),
        },
    },
    dialogPaper: {
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1),
        },
    },
    imageList: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
        width: '100%',
    },
    accordionSummaryContent: {
        alignItems: 'center',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '50%',
        flexShrink: 0,
    },
    secondaryHeading: {
        flexGrow: 1,
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    avatarRounded: {
        borderRadius: 8,
        width: 120,
        height: 120,
    },
}));

export default function DemandDetail() {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const theme = useTheme();
    const params = useParams();
    const location = useLocation();
    const isMatchedDemand = location.pathname.includes('matched-demands');
    const classes = useStyles();
    const { product, isProductFetchingInProgress } = useSelector((state) => ({
        product: isMatchedDemand ? state.ProductReducer.product?.demand : state.ProductReducer.product,
        isProductFetchingInProgress:
            state.ProductReducer.isProductFetchingInProgress,
    }));
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch({
            type: isMatchedDemand ? FETCH_MATCHED_DEMAND : FETCH_DEMAND,
            id: params.id,
            params: {
                _embed: isMatchedDemand ? 'demand.real_estate_properties' : 'real_estate_properties',
            },
        });
    }, []);

    if (isProductFetchingInProgress) {
        return (
            <Box paddingY={4}>
                <Grid container alignItems="center" direction="column">
                    <Grid item>
                        <ReactLoading
                            type={'bars'}
                            color={theme.palette.primary.main}
                            height={60}
                            width={60}
                        />
                    </Grid>
                    <Grid item>
                        <Typography>
                            <IntlMessages
                                id="Chargement en cours de la demande"
                                defaultMessage="Chargement en cours de la demande"
                            />
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    if (_.isEmpty(product)) return null;

    return (
        <Paper
            elevation={0}
            variant="outlined"
            classes={{ root: classes.paper }}
        >
            <Grid container spacing={isMobile ? 4 : 6} direction="column">
                <Grid item>
                    <Typography variant="h4" component="h2">
                        {product.name}
                    </Typography>
                    <Typography className={classes.demandDescription}>
                        {product.description}
                    </Typography>
                </Grid>
                <Grid item>
                    <div className={classes.root}>
                        <Images
                            alt={product.name}
                            images={product.images}
                            rowHeight={isMobile ? 'auto' : undefined}
                        />
                    </div>
                </Grid>
                <Grid item>
                    <RealEstates realEstates={product.real_estate_properties} />
                </Grid>
                <Grid item id="propositions">
                    <Proposals
                        product={product}
                        canAddProposition={isMatchedDemand && product.status !== 'finished'}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
}

const MAX_CHARACTERS = 500;

function TextToggle({ text }) {
    const [showFullText, toggleText] = React.useReducer((prev) => !prev, false);

    const isTextLong = text.length > MAX_CHARACTERS;

    const shownText =
        !showFullText && isTextLong
            ? text.slice(0, MAX_CHARACTERS) + '...'
            : text;

    return (
        <>
            <Typography>
                {shownText}
                {isTextLong && (
                    <Button
                        size="small"
                        color="primary"
                        variant="text"
                        component="span"
                        onClick={toggleText}
                    >
                        {showFullText ? (
                            <IntlMessages
                                id="Show less text"
                                defaultMessage="Show less text"
                            />
                        ) : (
                            <IntlMessages
                                id="Show more text"
                                defaultMessage="Show more text"
                            />
                        )}
                    </Button>
                )}
            </Typography>
        </>
    );
}

const ImagesDialog = ({ images, alt }) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = React.useState(false);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    return (
        <>
            <AvatarGroup
                onClick={() => setIsOpen(true)}
                max={2}
                classes={{ avatar: classes.avatarRounded }}
            >
                {images.map((image) => (
                    <Avatar
                        key={image.id}
                        variant="rounded"
                        alt={alt}
                        src={urlJoin(BASE_URL, image.name['500x500'])}
                    />
                ))}
            </AvatarGroup>
            <Dialog
                open={isOpen}
                onClose={() => setIsOpen(false)}
                classes={{ paper: classes.dialogPaper }}
                fullWidth={!isMobile}
                maxWidth="lg"
            >
                <Images images={images} />
            </Dialog>
        </>
    );
};

const Images = ({ images, alt, ...other }) => {
    const classes = useStyles();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    if (_.isEmpty(images)) return null;
    if (images.some((img) => img.url.includes('default-product-main-image')))
        return null;
    return (
        <ImageList
            className={classes.imageList}
            cols={isMobile ? 1 : 2.5}
            rowHeight="auto"
            {...other}
        >
            {images?.map((image) => (
                <ImageListItem key={image.id} rows={isMobile ? 1 : 2}>
                    <img
                        src={urlJoin(BASE_URL, image.name['500x500'])}
                        alt={alt}
                        className="object-contain"
                    />
                </ImageListItem>
            ))}
        </ImageList>
    );
};

const RealEstates = ({ realEstates }) => {
    if (_.isEmpty(realEstates)) return null;
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5" component="h3">
                    {realEstates.length > 1 ? (
                        <IntlMessages id="Les biens concernés par la demande" />
                    ) : (
                        <IntlMessages id="Le bien concerné par la demande" />
                    )}
                </Typography>
            </Grid>
            {realEstates?.map((realEstate) => (
                <Grid key={realEstate.id} item xs={12}>
                    <RealEstate realEstate={realEstate} />
                </Grid>
            ))}
        </Grid>
    );
};

export function getSpecificityValue(specificity) {
    switch (specificity.type) {
        case 'm_list':
            return _.map(
                specificity.choice,
                (choice) =>
                    specificity.choices.find(({ value }) => value === choice)
                        ?.label,
            )?.join(', ');
        case 'list':
            return _.find(
                specificity.choices,
                ({ value }) => value === specificity.choice,
            )?.label;
        case 'range':
            return specificity.choice[0] === specificity.choice[1]
                ? specificity.choice[0]
                : `[${specificity.choice.join(' - ')}]`;
        default:
            return specificity.choice;
    }
}

const RealEstate = ({ realEstate }) => {
    const classes = useStyles();

    const sections = realEstate.product_specificities.reduce(
        (acc, specificity) => {
            const section = specificity.specificity.section;
            if (section) {
                const existingSection = acc.find(
                    (existingSection) =>
                        existingSection.id === section.id &&
                        existingSection.name === section.name,
                );
                if (existingSection) {
                    existingSection.specificities.push(specificity);
                } else {
                    acc.push({
                        ...section,
                        specificities: [specificity],
                    });
                }
            }
            return acc;
        },
        [],
    );

    const specificitiesWithoutSection = realEstate.product_specificities.filter(
        (specificity) => _.isEmpty(specificity.specificity.section),
    );

    return (
        <Accordion variant="outlined" defaultExpanded>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                classes={{
                    content: classes.accordionSummaryContent,
                }}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography noWrap className={classes.heading}>
                    {realEstate.name}
                </Typography>
                <Grid container spacing={1}>
                    <Grid item>
                        <Icon>room</Icon>
                    </Grid>
                    <Grid item xs>
                        <Typography className={classes.secondaryHeading}>
                            {realEstate.address?.address_01}
                        </Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="h6">
                            <IntlMessages id="Real estate category" />
                        </Typography>
                        <Typography style={{ marginTop: 4 }}>
                            {realEstate.category.name}
                        </Typography>
                    </Grid>
                    {specificitiesWithoutSection.map((specificity) => (
                        <Grid key={specificity.specificity.id} item xs={6}>
                            <Typography variant="h6">
                                {specificity.specificity.name}
                            </Typography>
                            <Typography style={{ marginTop: 4 }}>
                                {getSpecificityValue(specificity)}
                            </Typography>
                        </Grid>
                    ))}
                    <Grid item container xs={12} spacing={2}>
                        {sections.map((section) => (
                            <Grid key={section.id} item xs={6}>
                                <Typography variant="h6">
                                    {section.name}
                                </Typography>
                                {section.specificities.map((specificity) => (
                                    <Typography style={{ marginTop: 4 }}>
                                        <Box component="span" fontWeight={500}>
                                            {specificity.specificity.name}:
                                        </Box>{' '}
                                        {getSpecificityValue(specificity)}
                                    </Typography>
                                ))}
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                        <ImagesDialog
                            images={realEstate.images}
                            alt={realEstate.name}
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

const Proposals = ({ product, canAddProposition }) => {
    const { propositions, isFetchingPropositionsInProgress } = useSelector((state) => ({
        propositions: state.propositionReducer.propositions,
        isFetchingPropositionsInProgress: state.propositionReducer.isFetchingPropositionsInProgress,
    }));
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch({
            type: FETCH_PROPOSITIONS,
            params: {
                'product.id': product.id,
                _sort: '-created_at',
                _embed: 'user.store'
            },
        });
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5" component="h3">
                    <IntlMessages id="Proposals" />
                </Typography>
            </Grid>
            {isFetchingPropositionsInProgress && (
                <Grid item xs={12}>
                    <Box textAlign='center' marginY={2}>
                        <CircularProgress />
                    </Box>
                </Grid>
            )}
            {_.isEmpty(propositions) && !isFetchingPropositionsInProgress ? (
                canAddProposition ? (
                    <Grid item xs={12}>
                        <AddPropositionButton product={product} />
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <Typography align="center">
                            <IntlMessages id="No proposals yet" />
                        </Typography>
                    </Grid>
                )
            ) : null}
            {propositions?.map((proposition) => (
                <Grid item xs={12}>
                    <Proposal
                        key={proposition.id}
                        proposition={proposition}
                        canAcceptProposition={
                            !canAddProposition && product.status !== 'finished'
                        }
                        fetchParams={{
                            'product.id': product.id,
                        }}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

const Proposal = ({ proposition, canAcceptProposition, fetchParams }) => {
    const classes = useStyles();
    const { addItemInProgress, isAcceptingPropositionInProgress } = useSelector((state) => ({
        addItemInProgress: state.shoppingCartReducer.addItemInProgress,
        isAcceptingPropositionInProgress: state.propositionReducer.isAcceptingPropositionInProgress,
    }));
    const dispatch = useDispatch();
    const history = useHistory();
    const [isAcceptDialogOpen, setIsAcceptDialogOpen] = React.useState(false);

    const handleOnAcceptPropositionSuccess = () => {
        dispatch({
            type: 'ADD_TO_CART',
            item_uuid: proposition.product.uuid,
            history,
            redirectTo: '/cart',
        });
    };

    const inProgress = addItemInProgress || isAcceptingPropositionInProgress;

    return (
        <>
            <Accordion defaultExpanded variant="outlined">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    classes={{
                        content: classes.accordionSummaryContent,
                    }}
                >
                    {proposition.user.store?.name ? (
                        <a
                            href={`${BASE_URL}stores/${proposition.user.store.id}`}
                            className={classes.heading}
                            rel="noopener noreferrer"
                        >
                            <Typography variant="h6" color="textPrimary">
                                {proposition.user.store.name}
                            </Typography>
                        </a>
                    ) : (
                        <Typography className={classes.heading} variant="h6" color="textPrimary">
                            {proposition.user.name}
                        </Typography>
                    )}
                    <Typography className={classes.secondaryHeading}>
                        <IntlNumber
                            value={proposition.price}
                            style="currency"
                            currency="EUR"
                        />
                    </Typography>
                    {proposition.status === 'waiting' &&
                        canAcceptProposition && (
                            <Button
                                variant="outlined"
                                color="primary"
                                disabled={inProgress}
                                onClick={() => setIsAcceptDialogOpen(true)}
                            >
                                <Typography noWrap>
                                    <IntlMessages id="Accept proposal" />
                                </Typography>
                            </Button>
                        )}
                    {proposition.status === 'accepted' &&
                        canAcceptProposition && (
                            <Button
                                variant="outlined"
                                color="primary"
                                disabled={inProgress}
                                onClick={handleOnAcceptPropositionSuccess}
                            >
                                <Typography noWrap>
                                    <IntlMessages id="Add proposal to Cart" />
                                </Typography>
                            </Button>
                        )}
                </AccordionSummary>
                <AccordionDetails>
                    <TextToggle text={proposition.description} />
                </AccordionDetails>
            </Accordion>
            <AcceptPropositionDialog
                id={proposition.id}
                onClose={() => setIsAcceptDialogOpen(false)}
                open={isAcceptDialogOpen}
                fetchParams={fetchParams}
                onSuccess={handleOnAcceptPropositionSuccess}
            />
        </>
    );
};

const AddPropositionButton = ({ product }) => {
    const dispatch = useDispatch();
    const [isAddPropositionDialogOpen, setIsAddPropositionDialogOpen] =
        React.useState(false);
    const handleOnAddPropositionSuccess = () => {
        setIsAddPropositionDialogOpen(false);
        dispatch({
            type: FETCH_PROPOSITIONS,
            params: {
                'product.id': product.id,
                _sort: '-created_at',
            },
        });
    };
    return (
        <Grid container justifyContent="center">
            <Button
                variant="outlined"
                color="primary"
                onClick={() => setIsAddPropositionDialogOpen(true)}
            >
                <Typography noWrap>
                    <IntlMessages id="Add proposal" />
                </Typography>
            </Button>
            <AddPropositionDialog
                product={product}
                open={isAddPropositionDialogOpen}
                onClose={handleOnAddPropositionSuccess}
            />
        </Grid>
    );
};
