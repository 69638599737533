import React, { useCallback, useMemo } from 'react';
import { Typography } from '@material-ui/core';
import { CustomButton } from './CustomButton';
import { FormControlLabelWrapper } from './FormControlLabelWrapper';
import Skeleton from '@material-ui/lab/Skeleton';
import _ from 'lodash';
import { IntlMessages } from 'react-platform-components/dist/Translations';
import { CATEGORY_FILTER_QUERY_KEY } from './CategoryFilterPopup';

export const CategoryFilter = ({
    categories,
    filters,
    handleChange,
    selectedCategory,
    handleCategoryClick,
}) => {
    const checkIfCategoryChecked = useCallback(
        (categoryId) => {
            return !!filters[CATEGORY_FILTER_QUERY_KEY]?.includes(
                String(categoryId),
            );
        },
        [filters],
    );

    const valueOfCheckboxAll = useMemo(() => {
        return selectedCategory
            ? [
                  ...selectedCategory.sub_categories
                      .map((subCategory) => subCategory.id)
                      .filter((subCategoryId) =>
                          checkIfCategoryChecked(subCategoryId),
                      ),
                  selectedCategory.id,
              ]
            : [];
    }, [selectedCategory, checkIfCategoryChecked]);
    if (_.isEmpty(categories))
        return (
            <div style={{ padding: '12px 8px' }}>
                {[1, 2, 3, 4, 5, 6].map((skeleton) => {
                    return <Skeleton animation="wave" height={50} />;
                })}
            </div>
        );
    return (
        <div>
            {selectedCategory && (
                <FormControlLabelWrapper
                    handleChange={handleChange}
                    checkboxValue={valueOfCheckboxAll}
                    isChecked={checkIfCategoryChecked(selectedCategory.id)}
                    filterName={CATEGORY_FILTER_QUERY_KEY}
                    label={<IntlMessages id="All" defaultMessage="All" />}
                />
            )}
            {(selectedCategory?.sub_categories || categories).map((category) => {
                const isParentChecked = checkIfCategoryChecked(
                    category.parent_category?.id,
                );
                const checkboxValue = isParentChecked
                    ? [category.parent_category?.id, category.id]
                    : category.id;
                return category.sub_categories?.length ? (
                    <CustomButton
                        doesFilterExist={false}
                        handleCancel={undefined}
                        handleGoForward={() => handleCategoryClick(category)}
                    >
                        <Typography>{category.name}</Typography>
                    </CustomButton>
                ) : (
                    <FormControlLabelWrapper
                        handleChange={handleChange}
                        checkboxValue={checkboxValue}
                        isChecked={
                            isParentChecked ||
                            checkIfCategoryChecked(category.id)
                        }
                        filterName={CATEGORY_FILTER_QUERY_KEY}
                        label={category.name}
                        key={category.name}
                    />
                );
            })}
        </div>
    );
};
