import { PurchaseOfBuyerConfig as PurchaseOfBuyerConfigDefault } from 'react-platform-purchase/dist/PurchaseOfBuyer/PurchaseOfBuyerConfig';
import { PurchaseOfBuyerDetailsContainer } from './PurchaseOfBuyerDetails';

export const PurchaseOfBuyerConfig = {
    ...PurchaseOfBuyerConfigDefault,
    routes: PurchaseOfBuyerConfigDefault.routes.map((route) =>
        route.path === '/buyer-purchases-details/:id'
            ? {
                  ...route,
                  component: PurchaseOfBuyerDetailsContainer,
              }
            : route,
    ),
};
