import { all, put, takeEvery } from 'redux-saga/effects';
import axios from 'react-platform-components/dist/axios';
import { BASE_API_URL } from 'react-platform-components/dist/config';
import { handleErrors } from 'react-platform-components/dist/Utils';

export const CREATE_DEMAND = 'CREATE_DEMAND';
export const CREATE_DEMAND_START = 'CREATE_DEMAND_START';
export const CREATE_DEMAND_SUCCESS = 'CREATE_DEMAND_SUCCESS';
export const CREATE_DEMAND_ERROR = 'CREATE_DEMAND_ERROR';

export const FETCH_DEMANDS = 'FETCH_DEMANDS';
export const FETCH_DEMAND = 'FETCH_DEMAND';

export const DELETE_DEMAND = 'DELETE_DEMAND';

export function* createDemand(action) {
    yield put({ type: CREATE_DEMAND_START });
    try {
        yield axios.post(
            `${BASE_API_URL}demands`,
            action.model,
            { headers: { 'Content-Type': 'multipart/form-data' } },
        );

        yield put({ type: CREATE_DEMAND_SUCCESS });
        action.onSuccess && action.onSuccess();
    } catch (error) {
        yield put({ type: CREATE_DEMAND_ERROR });
        yield put({ type: 'HANDLE_ERROR', error: handleErrors(error) });
    }
}

export function* fetchDemands(action) {
    yield put({
        ...action,
        type: 'FETCH_PRODUCT_SALABLES',
        endpoint: 'demands',
    });
}

export function* fetchDemand(action) {
    yield put({
        ...action,
        type: 'FETCH_PRODUCT',
        endpoint: 'demands',
    });
}

export function* deleteDemand(action) {
    yield put({
        ...action,
        type: 'DELETE_PRODUCT',
        endpoint: 'demands',
        listAction: FETCH_DEMANDS,
        filters: {
            ...action.filters,
            ne_status: undefined
        }
    });
}

export function* demandsSaga(){
    yield all([
        takeEvery(CREATE_DEMAND, createDemand),
        takeEvery(FETCH_DEMANDS, fetchDemands),
        takeEvery(FETCH_DEMAND, fetchDemand),
        takeEvery(DELETE_DEMAND, deleteDemand),
    ])
}