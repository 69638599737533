import { ProductSalableConfig } from 'react-platform-productsalable/dist/ProductSalable/ProductSalableConfig';
import { ServicesList } from './ServicesList';
import { categoryOfServiceSaga } from './ServicesActions';
import servicesReducer from './ServicesReducer';

export const ServicesListConfig = {
    ...ProductSalableConfig,
    rountes: [
        {
            component: ServicesList,
            path: "/product-salable",
        },
    ],
    actions: categoryOfServiceSaga,
    reducer: servicesReducer,
};
