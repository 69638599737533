import { all, put, takeEvery } from 'redux-saga/effects';
import axios from 'react-platform-components/dist/axios';
import {
    BASE_API_URL,
} from 'react-platform-components/dist/config';
import { handleErrors } from 'react-platform-components/dist/Utils';

export const UPLOAD_PURCHASE_FILES = 'UPLOAD_PURCHASE_FILES';
export const UPLOAD_PURCHASE_FILES_START = 'UPLOAD_PURCHASE_FILES_START';
export const UPLOAD_PURCHASE_FILES_SUCCESS = 'UPLOAD_PURCHASE_FILES_SUCCESS';
export const UPLOAD_PURCHASE_FILES_ERROR = 'UPLOAD_PURCHASE_FILES_ERROR';

function* uploadPurchaseFiles(action) {
    yield put({ type: UPLOAD_PURCHASE_FILES_START });
    try {
        yield axios.post(
            `${BASE_API_URL}purchase-item-seller-extensions`,
            action.model,
        );
        yield put({
            type: UPLOAD_PURCHASE_FILES_SUCCESS,
        });

        if (action.onSuccess) {
            action.onSuccess();
        }
    } catch (error) {
        yield put({ type: UPLOAD_PURCHASE_FILES_ERROR });
        yield put({ type: 'HANDLE_ERROR', error: handleErrors(error) });
    }
}

export function* purchaseSaga() {
    yield all([takeEvery(UPLOAD_PURCHASE_FILES, uploadPurchaseFiles)]);
}
