import React from 'react';

import { CreateProductSalable } from 'react-platform-productsalable/dist/CreateProductSalable/forms/CreateProductSalable';
import { CreateProductSalableForm } from 'react-platform-productsalable/dist/CreateProductSalable/forms/CreateProductSalableForm';
import { InformationsStepFields } from 'react-platform-productsalable/dist/CreateProductSalable/forms/steps/InformationStep';

export const CreateRealEstate = () => {
    return (
        <CreateProductSalable
            endpoint="real-estate-propertys"
            categoryOfProductEndpoint="category-of-real-estates"
            createProductSalableForm={CreateRealEstateForm}
        />
    );
};

export const CreateRealEstateForm = (props) => {
    return (
        <CreateProductSalableForm
            {...props}
            endpoint="real-estate-propertys"
            getStepsContent={(stepsContent) => {
                return stepsContent.map((step, index) => {
                    if (index === 0) {
                        step.component = InformationStep;
                    }
                    return step;
                });
            }}
            onSave={{
                redirectAfterActionTo: () => '/real-estates',
                excludedFields: (excludedFields) =>
                    excludedFields.concat(['price']),
                hasReservationOption: false,
            }}
        />
    );
};

const InformationStep = (props) => {
    return <InformationsStepFields {...props} priceComponent={() => <></>} />;
};
