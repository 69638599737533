import { PurchaseOfSellerConfig as PurchaseOfSellerConfigDefault } from 'react-platform-purchase/dist/PurchaseOfSeller/PurchaseOfSellerConfig';
import { PurchaseOfSellerDetailsContainer } from './PurchaseOfSellerDetails';
import { purchaseSaga } from './PurchaseActions';
import purchaseReducer from './PurchaseReducer';

export const PurchaseOfSellerConfig = {
    ...PurchaseOfSellerConfigDefault,
    routes: PurchaseOfSellerConfigDefault.routes.map((route) =>
        route.path === '/seller-purchases-details/:id'
            ? {
                  ...route,
                  component: PurchaseOfSellerDetailsContainer,
              }
            : route,
    ),
    reducer: purchaseReducer,
    actions: purchaseSaga,
};
