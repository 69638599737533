import { all, put, takeEvery } from 'redux-saga/effects';

export const DELETE_REAL_ESTATE = 'DELETE_REAL_ESTATE'

export function* fetchRealEstates(action) {
    yield put({
        ...action,
        type: 'FETCH_PRODUCTS',
        endpoint: 'real-estate-propertys',
    });
}

export function* deleteRealEstate(action) {
    yield put({
        ...action,
        type: 'DELETE_PRODUCT',
        endpoint: 'real-estate-propertys',
    });
}

export function* fetchCategoriesOfRealEstate(action) {
    yield put({
        ...action,
        type: 'FETCH_CATEGORIES_OF_PRODUCT',
        endpoint: 'category-of-real-estates',
    });
}

export function* realEstatesSaga() {
    yield all([
        takeEvery('FETCH_REAL_ESTATES', fetchRealEstates),
        takeEvery(DELETE_REAL_ESTATE, deleteRealEstate),
        takeEvery(
            'FETCH_CATEGORIES_OF_REAL_ESTATE',
            fetchCategoriesOfRealEstate,
        ),
    ]);
}
