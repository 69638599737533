import React from 'react';


class Dashboard extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return <div />
    }
}

export default Dashboard